<template>
  <div id="news" v-show="ConShow" class="animated fadeInUp">
    <Item v-if="url === 'Item'" />
    <Content v-if="url === 'Content'" />
  </div>
</template>
<script>
import Item from "./components/item";
import Content from "./components/content";
export default {
  data() {
    return {
      ConShow: false,
      url: "Item",
    };
  },
  metaInfo: {
    title:
      "夏龙通信官网--新闻中心",
    meta: [
      {
        name: "keywords",
        content:
          "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入--新闻中心",
      },
      {
        name: "description",
        content:
          "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--新闻中心",
      },
    ],
  },
  components: {
    Item,
    Content,
  },
  mounted() {
    this.ConShow = true;
    this.$eventbus.$emit("banner_id", 17);
    localStorage.setItem("banner_id", 17);
    // console.log(localStorage.getItem("news_url"));
    console.log(this.$route.query);
    if (JSON.stringify(this.$route.query) == '{}') {
      // console.log(1)
      this.$eventbus.$on("news_url", (url) => {
        // console.log(url);
        this.url = url;
      });
      if (
        localStorage.getItem("news_url") != undefined ||
        localStorage.getItem("news_url") != null ||
        localStorage.getItem("news_url") != ""
      ) {
        this.url = localStorage.getItem("news_url");
      }
    } else {
      // console.log(2)
      this.url = this.$route.query.news_url;
      localStorage.setItem("news_id", this.$route.query.news_id);
    }
  },
  methods: {},
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";
#news {
  width: 100%;
  min-height: 20rem;
  //   background: blanchedalmond;
  .column;
  justify-content: flex-start;
}
</style>