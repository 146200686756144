<template>
  <div id="cid_5" class="animated fadeInUp">
    <!-- 图片卡 -->
    <p class="title">{{modular.content.list[0].title}}</p>
    <img :src="modular.content.list[0].image" alt />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: ["modular"],
  methods: {},
  mounted() {},
};
</script>
<style scoped lang="less">
@import "../../../../assets/css/main.less";
#cid_5 {
  width: 100%;
  min-height: 35rem;
  // background: #999;
  .column;
  justify-content: space-around;
  .title {
    font-size: 1.5rem;
    font-weight: bolder;
  }
  img {
    width: 75rem;
    // height: 25rem;
  }
}
</style>