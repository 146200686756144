<template>
  <div id="cid_1" class="animated fadeInUp">
    <!-- 方案架构 -->
    <!-- @click="enlarge(modular.content.list[0].image)" -->
    <p class="title">{{modular.content.title}}</p>
    <section>
      <aside>
        <img :src="modular.content.list[0].image" alt />
      </aside>
      <aside>
        <div>
          <strong>{{modular.content.list[0].title1}}</strong>
          <p v-html="modular.content.list[0].remark1"></p>
        </div>
        <div>
          <strong>{{modular.content.list[0].title2}}</strong>
          <p v-html="modular.content.list[0].remark2"></p>
        </div>
      </aside>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: ["modular"],
  methods: {},
  mounted() {},
};
</script>
<style scoped lang="less">
@import "../../../../assets/css/main.less";
#cid_1 {
  width: 75rem;
  height: 36.2rem;
  // background: blanchedalmond;
  .column;
  justify-content: space-around;
  color: black;
  .title {
    font-size: 1.5rem;
    font-weight: bolder;
  }
  section {
    width: 100%;
    height: 31.25rem;
    // background: #f9f9f9;
    .row;
    justify-content: space-between;
    transition: all ease 1s;
    aside:nth-of-type(1) {
      width: 55%;
      height: 100%;
      // background: #eeeeee;
      .row;
      justify-content: center;
      img {
        width: 90%;
        // .enlarge;
      }
    }
    aside:nth-of-type(2) {
      width: 45%;
      height: 100%;
      // background: coral;
      .column;
      justify-content: space-between;
      div {
        width: 90%;
        height: 100%;
        .column;
        justify-content: center;
        align-items: flex-start;
      }
      div:nth-of-type(2) {
        border-top: #eee 1px solid;
        padding-top: 1rem;
      }
    }
  }
  section:hover {
    background: rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  }
}
</style>