<template>
  <div id="RightTop">
    <div id="Backtop" @click="backtop">
      <img :src="top" alt="回到顶部" />
    </div>
    <div id="BizQQWPA">
      <a target="_blank">
        <img src="../../assets/images/qq.png" style="width: 4.5rem" alt="qq" />
      </a>
    </div>
  </div>
</template>

<script>
import top from "../../assets/images/backtop.png";
export default {
  data() {
    return {
      top,
    };
  },
  methods: {
    nav_top() {
      document.querySelector("#header header").style.position = "fixed";
      document.querySelector("#header header").style.height = "5rem";
      document.querySelector("#header header").style.top = "0";
      document.querySelector("#header header").style.background =
        "rgba(1, 35, 124, 1)";
      document.querySelector("#header header #logo").style.margin = "0 50px";
      document.querySelector(
        "#header header #internationalization"
      ).style.opacity = "0";
    },
    top_nav() {
      document.querySelector("#header header").style.position = "absolute";
      document.querySelector("#header header").style.top = "0";
      document.querySelector("#header header").style.background = "none";
      document.querySelector("#header header #logo").style.margin = "0";
      // document.querySelector("#header header #logo").style.opacity = "1";
      document.querySelector(
        "#header header #internationalization"
      ).style.opacity = "1";
    },
    top_Backtop() {
      document.querySelector("#Backtop").style.opacity = "0";
    },
    Backtop_top() {
      document.querySelector("#Backtop").style.opacity = "1";
    },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop);
      if (this.$route.meta.nav) {
        if (scrollTop > 900) {
          // console.log(scrollTop);
          this.nav_top();
        } else if (scrollTop < 899) {
          this.top_nav();
        }
      } else {
        this.top_nav();
      }
      if (this.$route.meta.backtop) {
        if (scrollTop > 900) {
          this.Backtop_top();
        } else if (scrollTop < 899) {
          this.top_Backtop();
        }
      } else {
        this.top_Backtop();
      }
    },
    backtop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 10);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";
#Backtop {
  position: fixed;
  bottom: 10rem;
  right: 5%;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
  background: #ccc;
  .column;
  justify-content: center;
  // display: none;
  transition: ease-in-out 1s all;
  opacity: 0;
  img {
    width: 70%;
    height: 70%;
  }
}

#BizQQWPA {
  z-index: 99999999;
  position: fixed;
  bottom: 16rem;
  right: 5%;
  a {
    border-radius: 50%;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    width: 5rem;
    height: 5rem;
    background: #ccc;
    .column;
    justify-content: center;
  }
}
</style>