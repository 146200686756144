/*
 * @Author: Smonua
 * @LastEditors: Smonua
 * @email: smonua@163.com
 * @github: https://github.com/Smonua
 * @Date: 2020-12-07 18:59:47
 * @LastEditTime: 2021-06-02 10:11:18
 * @motto: Cats are not suitable for dogs
 * @Description: 生性属猫不宜作犬
 * @FilePath: \remoteguidanced:\HP\夏龙门户网站\dragon\src\main.js
 */
import Vue from 'vue';
import App from './App.vue';
import './plugins/element.js';
import "./eventbus";
import "./assets/css/font-awesome-4.7.0/css/font-awesome.min.css";
import "./assets/css/pushy-buttons.min.css";
import "./assets/css/hover.css"
import BaiduMap from 'vue-baidu-map'


// import MetaInfo from 'vue-meta-info'
// Vue.use(MetaInfo)


import '@babel/polyfill'

import axios from 'axios'
Vue.prototype.$axios = axios

import router from "./router";
import animated from 'animate.css';

import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad, {
    preLoad: 1.3,
    attempt: 1,
    try: 3,
    error: '../../assets/err.jpg',
    loading: '../../assets/loading.gif'
})


Vue.use(animated);

Vue.use(BaiduMap, {
    ak: 'TZlNMRRZPq9Yt4dD4XwDGAqTULvwvzwm' //这个地方是官方提供的ak密钥
})


router.afterEach((transition) => {
    console.log(transition)
    window.prerenderReady = true
})


Vue.config.productionTip = false


router.afterEach(() => {
    window.scrollTo(0, 0)
})

router.beforeEach((to, from, next) => {
    window.prerenderReady = false
    console.log(to.meta.content, '123', to.meta.title)
    if (to.meta.content) {
        // console.log(document.querySelector('meta[name="keywords"]'))
        // console.log(to.meta.content)
        addMeta("keywords", to.meta.content.keywords)
        addMeta("description", to.meta.content.description)
    }
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next()
});

function addMeta(name, content) { //手动添加mate标签
    let meta = document.createElement('meta');
    meta.content = content;
    meta.name = name;
    document.getElementsByTagName('head')[0].appendChild(meta);
}


new Vue({
    router,
    render: h => h(App),

}).$mount('#app')

// /, /cloud, /bigdata, /solution, /consultation, /about, /apply
// mounted () {
//         document.dispatchEvent(new Event('render-event'))
//     }