<template>
  <div id="service">
    <!-- <article id="title_article">
      <span class="icon_span">
        <i class="el-icon-bell"></i>
      </span>
      <section>
        <span>搜索</span>
        <el-input
          placeholder="请输入内容"
          v-model="input_search"
          class="input-with-select"
          @keydown="keydown($event)"
          clearable
        >
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
      </section>
    </article>-->

    <div v-if="Page_flag" class="animated fadeInRight">
      <article name="enterprise">
        <section class="title">企业资质</section>
        <section class="content">
          <aside v-for="el in enterprise" :key="el.id">
            <img v-lazy="el.small_image" @click="enlarge(el.image)" alt="企业资质"   />
            <span>{{el.name}}</span>
          </aside>
        </section>
      </article>

      <article name="cooperation">
        <section class="title">合作授权</section>
        <section class="content">
          <aside v-for="el in cooperation" :key="el.id">
            <img v-lazy="el.small_image" @click="enlarge(el.image)" alt="合作授权" />
            <span>{{el.name}}</span>
          </aside>
        </section>
      </article>

      <article name="industry">
        <section class="title">行业认证</section>
        <section class="content">
          <aside v-for="item in industry" :key="item.id">
            <img v-lazy="item.small_image" @click="enlarge(item.image)" :alt="item.name" />
            <span>{{item.name}}</span>
          </aside>
        </section>
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="current1"
          :hide-on-single-page="value"
          :total="count1"
          :page-size="page_size"
        ></el-pagination>
      </article>

      <article name="knowledge">
        <section class="title">知识产权</section>
        <section class="content">
          <el-carousel height="500px" :autoplay="false" type="card" indicator-position="none">
            <el-carousel-item v-for="item in knowledge" :key="item.id">
              <img v-lazy="item.small_image" @click="enlarge(item.image)" :alt="item.name" />
              <span>{{item.name}}</span>
            </el-carousel-item>
          </el-carousel>
        </section>
      </article>
    </div>

    <div v-else>
      <article name="keyword">
        <section class="content">
          <aside v-for="el in keylist" :key="el.id">
            <img v-lazy="el.small_image" @click="enlarge(el.image)" />
            <span>{{el.name}}</span>
          </aside>
        </section>
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="keywordCurrentChange"
          :current-page="current2"
          :hide-on-single-page="value"
          :total="count2"
          :page-size="page_size"
        ></el-pagination>
      </article>
    </div>

    <div id="images">
      <article class="animated fadeInRightBig" v-show="img_show">
        <img :src="images" @click="narrow"  />
      </article>
    </div>
  </div>
</template>

<script>
import { host } from "../../const";
export default {
  data() {
    return {
      Page_flag: true,
      img_show: false,
      input_search: "",
      select_search: "",
      page_size: 6,
      current1: 1,
      count1: 11,
      current2: 1,
      count2: 11,
      value: false,
      images: "",
      enterprise: [],
      cooperation: [],
      industry: [],
      knowledge: [],
      keylist: [],
      qualist: [],
    };
  },
  metaInfo: {
    title: "夏龙通信官网--服务资质",
    meta: [
      {
        name: "keywords",
        content:
          "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入--服务资质",
      },
      {
        name: "description",
        content:
          "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--服务资质",
      },
    ],
  },
  components: {},
  props: [],
  methods: {
    enlarge(img) {
      this.img_show = true;
      this.images = img;
      document.querySelector("#images").style.display = "block";
    },
    narrow() {
      let Images = document.querySelector("#images").style;
      Images.display = "none";
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.getqualificationlist(val, 3, "");
    },
    keywordCurrentChange(val) {
      if (!this.Page_flag) {
        this.getqualificationlist(val, "", this.input_search);
      }
    },
    search() {
      if (this.input_search != "") {
        this.Page_flag = false;
        this.getqualificationlist(1, "", this.input_search);
      } else {
        this.Page_flag = true;
      }
    },
    keydown(e) {
      console.log(12345);
      if (e.keyCode == 13) {
        alert("你按回车键了");
      }
    },
    getqualificationlist(page, tid, keyword) {
      // console.log(page, tid, keyword);
      let url =
        host +
        "/portal/home/getqualificationlist?page=" +
        page +
        "&tid=" +
        tid +
        "&keyword=" +
        keyword;
      this.$axios.get(url).then((res) => {
        // console.log(res.data.data.item);
        if (this.Page_flag) {
          if (tid == 1) {
            this.enterprise = res.data.data.item;
          } else if (tid == 2) {
            this.cooperation = res.data.data.item;
          } else if (tid == 3) {
            this.industry = res.data.data.item;
            this.count1 = res.data.data.count;
          } else if (tid == 4) {
            this.knowledge = res.data.data.item;
          }
        } else {
          this.keylist = res.data.data.item;
          this.count2 = res.data.data.count;
        }
      });
    },
    getquaclasslist() {
      let url = host + "/portal/home/getquaclasslist";
      this.$axios.get(url).then((res) => {
        // console.log(res.data.data);
        this.qualist = res.data.data;
      });
    },
  },

  mounted() {
    this.$eventbus.$emit("banner_id", 18);
    localStorage.setItem("banner_id", 18);
    this.getquaclasslist();
    this.getqualificationlist(1, 1, "");
    this.getqualificationlist(1, 2, "");
    this.getqualificationlist(1, 3, "");
    this.getqualificationlist(1, 4, "");
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";
#service {
  width: 75rem;
  min-height: 50rem;
  margin: 0 auto;
  color: black;
  #title_article {
    // background: cornflowerblue;
    width: 100%;
    height: 4rem;
    color: black;
    .row;
    justify-content: space-between;
    border-bottom: black solid 0.125rem;
    padding-top: 3rem;
    .icon_span {
      width: 8%;
      height: 100%;
      .row;
      justify-content: space-between;
      i {
        color: red;
      }
    }
    section {
      width: 60%;
      height: 100%;
      // background: brown;
      .row;
      justify-content: space-between;
      .el-input {
        width: 93%;
      }
    }
  }
  article[name="enterprise"],
  article[name="cooperation"] {
    margin-top: 2rem;
    width: 100%;
    min-height: 30rem;
    // background: brown;
    .column;
    justify-content: space-between;
    .title {
      width: 100%;
      height: 45px;
      background: #03257d;
      .row;
      justify-content: center;
      color: white;
    }
    .content {
      width: 100%;
      height: 25rem;
      // background: burlywood;
      .row;
      justify-content: space-around;
      aside {
        width: 30%;
        height: 100%;
        // background: chocolate;
        .column;
        justify-content: space-around;
        img {
          width: 90%;
          height: 300px;
          // border: 1px solid #ccc;
          padding: 10px 20px;
          .enlarge;
        }
      }
    }
  }
  article[name="cooperation"] {
    .content {
      img {
        width: 21.5%;
      }
    }
  }
  article[name="industry"] {
    margin-top: 2rem;
    width: 100%;
    min-height: 40rem;
    // background: brown;
    .column;
    justify-content: space-between;
    .title {
      width: 100%;
      height: 45px;
      background: #03257d;
      .row;
      justify-content: center;
      color: white;
    }
    .content {
      margin-bottom: 30px;
      width: 100%;
      min-height: 45rem;
      // background: burlywood;
      .row;
      flex-wrap: wrap;
      justify-content: flex-start;
      aside {
        width: 30%;
        height: 350px;
        // background: chocolate;
        margin-left: 30px;
        .column;
        justify-content: space-around;
        img {
          width: 90%;
          height: 280px;
          // padding: 10px 20px;
          .enlarge;
        }
      }
    }
  }
  article[name="knowledge"] {
    margin: 2rem 0;
    width: 100%;
    min-height: 36.5rem;
    // background: brown;
    .column;
    justify-content: space-between;
    .title {
      width: 100%;
      height: 45px;
      background: #03257d;
      .row;
      justify-content: center;
      color: white;
    }
    .content {
      width: 100%;
      height: 30rem;
      // background: chartreuse;
      .el-carousel {
        width: 100%;
        height: 500px;
        // background: chocolate;
        img {
          width: 50%;
          .enlarge;
        }
      }
      .el-carousel__item {
        .column;
        justify-content: space-around;
      }
    }
  }
  article[name="keyword"] {
    margin: 2rem 0;
    width: 100%;
    min-height: 40rem;
    // background: orange;
    .column;
    justify-content: space-between;
    .content {
      width: 100%;
      min-height: 40rem;
      .row;
      flex-wrap: wrap;
      justify-content: flex-start;
      // align-content: space-around;
      aside {
        margin-left: 30px;
        // background: blanchedalmond;
        width: 30%;
        height: 300px;
        .column;
        justify-content: space-around;
        //   background: beige;
        img {
          width: 86%;
          height: 260px;
        }
      }
    }
  }
}

#images {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  transition: all ease-in-out 1s;
  article {
    width: 100%;
    height: 100%;
    .row;
    justify-content: center;
    img {
      width: 38%;
      .narrow;
    }
  }
}
</style>