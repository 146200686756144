<template>
  <div id="tabbar">
    <div id="title">
      <div class="title" v-for="item in tabbar" :key="item.id" @click="jump(item)">
        <span class="title_span">{{item.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["tabbar"],
  methods: {
    jump(item) {
      this.$eventbus.$emit("tabbar_id", item.id);
      localStorage.setItem("tabbar_id", item.id);
      let title = document.querySelectorAll(".title span");
      title.forEach(el => {
        if (el.innerHTML.substring(0, 4) === item.name) {
          // console.log(el.innerHTML);
          el.style.borderBottom = "2px solid rgba(1, 35, 124, 1)";
        } else {
          el.style.borderBottom = "none";
        }
      });
    }
  },
  mounted() {}
};
</script>
<style scoped lang="less">
@import "../../../assets/css/main.less";
#tabbar {
  width: 100%;
  height: 5.375rem;
  background: white;
  box-shadow: 2px 2px 5px #ccc;
  #title {
    width: 50%;
    height: 100%;
    margin: 0 auto;
    // background: orange;
    color: black;
    .row;
    justify-content: center;
    .title {
      width: 100%;
      height: 100%;
      //   background: blanchedalmond;
      .row;
      justify-content: space-around;
      color: black;
      .title_span {
        padding: 10px 0;
      }
      // .title_span:hover {
      //   border-bottom: 1px solid black;
      // }
    }
    .title:nth-of-type(1) {
      // background: blanchedalmond;
      .title_span {
        border-bottom: 2px solid @bg;
      }
    }
  }
}
</style>