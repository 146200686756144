<template>
  <div id="partners" v-show="ConShow" class="animated fadeInUp">
    <div class="partners">
      <article>
        <aside v-for="item in card_content" :key="item.id">
          <img :src="item.image" @click="enlarge(item.image)" :alt="item.name" />
          <!-- <span>{{item.name}}</span> -->
        </aside>
      </article>
      <el-pagination
        background
        layout="prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :hide-on-single-page="value"
        :total="count"
        :page-size="page_size"
      ></el-pagination>
    </div>
    <div id="images">
      <article>
        <img :src="images" @click="narrow" alt />
      </article>
    </div>
  </div>
</template>
<script>
// import Tabbar from "./components/tabbar";
import product from "../../assets/images/product.jpg";
import { host } from "../../const";
export default {
  data() {
    return {
      input_search: "",
      value: false,
      card_content: [],
      current: 1,
      page_size: 9,
      count: 9,
      item_text: "",
      images: product,
      Type: "",
      ConShow: false,
    };
  },
  metaInfo: {
    title: "夏龙通信官网--合作伙伴",
    meta: [
      {
        name: "keywords",
        content:
          "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入--合作伙伴",
      },
      {
        name: "description",
        content:
          "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--合作伙伴",
      },
    ],
  },
  components: {
    // Tabbar
  },
  mounted() {
    this.getpartnerandqualist("partner", "1", "");
  },
  methods: {
    getpartnerandqualist(type, page, keyword) {
      this.ConShow = false;
      this.$eventbus.$emit("banner_id", 15);
      localStorage.setItem("banner_id", 15);
      let url =
        host +
        "/portal/home/getpartnerandqualist?type=" +
        type +
        "&page=" +
        page +
        "&keyword=" +
        keyword;
      this.$axios.get(url).then((res) => {
        // console.log("合作伙伴", res.data.data);
        this.ConShow = true;
        this.card_content = res.data.data.item;
        this.page_size = res.data.data.num;
        this.count = res.data.data.count;
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.getpartnerandqualist("partner", val, this.input_search);
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 10);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    enlarge(img) {
      if (this.Type == "qua") {
        // console.log(img);
        this.images = img;
        document.querySelector("#images").style.display = "block";
      }
    },
    narrow() {
      document.querySelector("#images").style.display = "none";
    },
    search() {
      if (this.input_search != "") {
        this.getpartnerandqualist(this.Type, "1", this.input_search);
      }
    },
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";
#partners {
  width: 100%;
  min-height: 10rem;
  // background: blanchedalmond;
  .partners {
    width: 75rem;
    height: 100%;
    // background: bisque;
    margin: 0 auto;
    .column;
    justify-content: center;
    article:nth-of-type(2) {
      // background: cornflowerblue;
      width: 100%;
      height: 4rem;
      color: black;
      .row;
      justify-content: space-between;
      border-bottom: black solid 0.125rem;
      padding-top: 3rem;
      .icon_span {
        width: 8%;
        height: 100%;
        .row;
        justify-content: space-between;
        i {
          color: red;
        }
      }
      section {
        width: 50%;
        height: 100%;
        // background: brown;
        .row;
        justify-content: space-between;
        .el-input {
          width: 65%;
        }
      }
    }
    article:nth-of-type(1) {
      width: 100%;
      min-height: 10rem;
      // background: cadetblue;
      margin: 3.125rem 0;
      .row;
      flex-wrap: wrap;
      justify-content: space-between;
      aside {
        width: 30%;
        height: 18rem;
        // background: beige;
        margin-top: 2rem;
        color: black;
        .column;
        justify-content: space-around;
        img {
          width: 50%;
          // .enlarge;
        }
      }
    }
    .el-pagination {
      margin-bottom: 2rem;
    }
  }
  #images {
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    article {
      width: 100%;
      height: 100%;
      // background: floralwhite;
      // margin: 12.5% 0 0 25%;
      // position: fixed;
      // top: 1%;
      // left: 30%;
      .row;
      justify-content: center;
      img {
        width: 38%;
        .narrow;
      }
    }
  }
}
</style> 