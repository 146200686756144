import Vue from "vue";
import VueRouter from "vue-router";

import Index from "../pages/index";
import Cloud from "../pages/cloud";
import Azure from "../pages/cloud/azure";
import BigData from "../pages/bigdata";
import Content from "../pages/bigdata/content";
import Solution from "../pages/solution";
import Consultation from "../pages/consultation";
import About from "../pages/about";
import Apply from "../pages/apply";

Vue.use(VueRouter);

const routes = [{
    name: '/',
    path: "/",
    component: Index,
    meta: {
        banner: true,
        header: true,
        footer: true,
        nav: true,
        backtop: true,
        solution_banner: false,
        title: "夏龙通信官网",
        content: {
            keywords: "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入,超算,科算云--首页",
            description: "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--首页"
        }

    },
    beforeEnter: (to, from, next) => {
        next();
    }
},
{
    name: 'Cloud',
    path: "/cloud",
    component: Cloud,
    meta: {
        banner: false,
        header: true,
        footer: true,
        nav: true,
        backtop: true,
        solution_banner: false,
        title: "夏龙通信官网",
        content: {
            keywords: "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入,超算,科算云--云服务",
            description: "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--云服务"
        }
    },
    children: [
        {
            path: '/cloud/Azure', component: Azure,
            meta: {
                banner: false,
                header: true,
                footer: true,
                nav: true,
                backtop: true,
                solution_banner: false,
                title: "夏龙通信官网",
                content: {
                    keywords: "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入,超算,科算云--云服务",
                    description: "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--云服务"
                }
            },
        }
    ]
},
{
    name: 'BigData',
    path: "/bigdata",
    component: BigData,
    meta: {
        banner: false,
        header: true,
        footer: true,
        nav: true,
        backtop: true,
        solution_banner: false,
        title: "夏龙通信官网",
        content: {
            keywords: "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入,超算,科算云--大数据",
            description: "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--大数据"
        }
    },
    children: [
        {
            path: '/bigdata/Content', component: Content,
            meta: {
                banner: false,
                header: true,
                footer: true,
                nav: true,
                backtop: true,
                solution_banner: false,
                title: "夏龙通信官网",
                content: {
                    keywords: "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入,超算,科算云--大数据",
                    description: "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--大数据"
                }
            },
        }
    ]
},
{
    name: 'Solution',
    path: "/solution",
    component: Solution,
    meta: {
        banner: false,
        header: true,
        footer: true,
        nav: false,
        backtop: true,
        solution_banner: true,
        title: "夏龙通信官网",
        content: {
            keywords: "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入,超算,科算云--解决方案",
            description: "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--解决方案"
        }
    },
}, {
    name: 'Consultation',
    path: "/consultation",
    component: Consultation,
    meta: {
        banner: false,
        header: true,
        footer: true,
        nav: true,
        backtop: true,
        solution_banner: false,
        title: "夏龙通信官网",
        content: {
            keywords: "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入,超算,科算云--咨询服务",
            description: "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--咨询服务"
        }
    },
}, {
    name: 'About',
    path: "/about",
    component: About,
    meta: {
        banner: false,
        header: true,
        footer: true,
        nav: true,
        backtop: true,
        solution_banner: false,
        title: "夏龙通信官网",
        content: {
            keywords: "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入,超算,科算云--关于我们",
            description: "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--关于我们"
        }
    },
}, {
    path: "/about/:type",
    component: About,
    meta: {
        banner: false,
        header: true,
        footer: true,
        nav: true,
        backtop: true,
        solution_banner: false,
        title: "夏龙通信官网",
        content: {
            keywords: "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入,超算,科算云--关于我们",
            description: "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--关于我们"
        }
    },
}, {
    path: "/apply",
    component: Apply,
    name: 'Apply',
    meta: {
        banner: false,
        header: false,
        footer: false,
        nav: true,
        backtop: true,
        solution_banner: false,
        title: "夏龙通信官网",
        content: {
            keywords: "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入,超算,科算云--咨询申请",
            description: "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--咨询申请"
        }
    },
},
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
    routes,
    mode: 'history',
    // base: process.env.NODE_ENV,
})

export default router;