<template>
  <div id="solution">
    <Tabbar :tabbar="tabbar" id="tabbar"></Tabbar>
    <div id="con_tent">
      <article id="modular_1" v-show="modular[0].card_Show">
        <Card :modular="modular[0]" />
      </article>
      <article id="modular_2" v-show="modular[1].card_Show">
        <Card :modular="modular[1]" />
      </article>
      <article id="modular_3" v-show="modular[2].card_Show">
        <Card :modular="modular[2]" />
      </article>
      <article id="modular_4" v-show="modular[3].card_Show">
        <Card :modular="modular[3]" />
      </article>
      <article id="modular_5" v-show="modular[4].card_Show">
        <Card :modular="modular[4]" />
      </article>
      <article id="modular_6" v-show="modular[5].card_Show">
        <Card :modular="modular[5]" />
      </article>
    </div>
  </div>
</template>

<script>
import logo from "../../assets/images/news_content.jpg";
import product from "../../assets/images/product.jpg";
import Tabbar from "./components/tabbar.vue";
import Card from "./components/card.vue";
import { host } from "../../const";
export default {
  data() {
    return {
      tabbar: ["1"],
      conlist: [],
      content: "",
      product: product,
      logo: logo,
      images: product,
      modular: [
        {
          card_Show: false,
          content: [],
        },
        {
          card_Show: false,
          content: [],
        },
        {
          card_Show: false,
          content: [],
        },
        {
          card_Show: false,
          content: [],
        },
        {
          card_Show: false,
          content: [],
        },
        {
          card_Show: false,
          content: [],
        },
      ],
    };
  },
  metaInfo: {
    title: "夏龙通信官网--解决方案",
    meta: [
      {
        name: "keywords",
        content:
          "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入--解决方案",
      },
      {
        name: "description",
        content:
          "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--解决方案",
      },
    ],
  },
  components: {
    Tabbar,
    Card,
  },
  methods: {
    getCarData(num, data) {
      this.modular[num - 1].card_Show = true;
      this.modular[num - 1].content = data.child[num - 1];
    },
    getsolutionlist(id) {
      this.modular.forEach((el) => {
        el.card_Show = false;
      });
      if (id != 0) {
        let url = host + "/portal/home/getsolutionlist?id=" + id;
        this.$axios.get(url).then((res) => {
          // console.log("页面", res.data.data);
          let banner_con = {
            allow: res.data.data[0].allow,
            title: res.data.data[0].name,
            content: res.data.data[0].remark,
            image: res.data.data[0].image,
            small_image: res.data.data[0].small_image,
            sid: id,
          };
          this.$eventbus.$emit("banner_con", banner_con);
          this.tabbar = [];

          // console.log(res.data.data[0]);
          if (res.data.data[0].child.length != 0) {
            res.data.data[0].child.forEach((el) => {
              this.tabbar.push(el.title);
            });
            // console.log(this.tabbar, this.tabbar.length);

            document.querySelector(
              "#solution article:nth-of-type(1)"
            ).style.left = "none";
            let Article = document.querySelector("#solution article").style;
            Article.width = parseInt(100 / this.tabbar.length);
            // console.log(parseInt(100 / this.tabbar.length));

            let tabbar = document.querySelector("#solution #tabbar");
            tabbar.style.position = "absolute";
            tabbar.style.top = "0px";
            this.getstyle(1);
            // console.log(this.tabbar, this.tabbar.length);
            let resData = res.data.data[0];
            if (this.tabbar.length == 1) {
              this.getCarData(1, resData);
            } else if (this.tabbar.length == 2) {
              this.getCarData(1, resData);
              this.getCarData(2, resData);
            } else if (this.tabbar.length == 3) {
              this.getCarData(1, resData);
              this.getCarData(2, resData);
              this.getCarData(3, resData);
            } else if (this.tabbar.length == 4) {
              this.getCarData(1, resData);
              this.getCarData(2, resData);
              this.getCarData(3, resData);
              this.getCarData(4, resData);
            } else if (this.tabbar.length == 5) {
              this.getCarData(1, resData);
              this.getCarData(2, resData);
              this.getCarData(3, resData);
              this.getCarData(4, resData);
              this.getCarData(5, resData);
            } else if (this.tabbar.length == 6) {
              this.getCarData(1, resData);
              this.getCarData(2, resData);
              this.getCarData(3, resData);
              this.getCarData(4, resData);
              this.getCarData(5, resData);
              this.getCarData(6, resData);
            }
            // console.log(this.modular);
          }
        });
      } else {
        // this.$message("目前还没有数据");
        this.$router.push("/");
      }
    },
    getstyle(num) {
      let item = document.querySelector(
        `#solution #tabbar #title .title a:nth-of-type(${num})`
      );
      item.style.color = "rgba(1, 35, 124, 1)";
      item.style.borderBottom = "2px solid rgba(1, 35, 124, 1)";
    },
    handleScroll() {
      let modular1 = document.querySelector("#solution #con_tent #modular_1")
        .offsetHeight;
      let modular2 = document.querySelector("#solution #con_tent #modular_2")
        .offsetHeight;
      let modular3 = document.querySelector("#solution #con_tent #modular_3")
        .offsetHeight;
      let modular4 = document.querySelector("#solution #con_tent #modular_4")
        .offsetHeight;
      let modular5 = document.querySelector("#solution #con_tent #modular_5")
        .offsetHeight;
      let modular6 = document.querySelector("#solution #con_tent #modular_6")
        .offsetHeight;
      // console.log(modular1, modular2, modular3, modular4, modular5, modular6);
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop);
      let tabbar = document.querySelector("#solution #tabbar");
      let Title_A = document.querySelectorAll(
        "#solution #tabbar #title .title a"
      );
      // console.log(Title_A);

      Title_A.forEach((el) => {
        // console.log(el);
        el.style.color = "black";
        el.style.borderBottom = "none";
        tabbar.style.position = "absolute";
        tabbar.style.top = "0";
        tabbar.style.left = "0px";
        // let num = this.tabbar.indexOf(el.innerHTML);
        // console.log(num);
        let headertop = 600;
        if (scrollTop > headertop) {
          tabbar.style.position = "fixed";
          tabbar.style.top = "0";
          if (scrollTop > modular1 && scrollTop < modular1 + headertop - 1) {
            this.getstyle(1);
          } else if (
            scrollTop > modular1 + headertop &&
            scrollTop < modular1 + modular2 + headertop - 1
          ) {
            this.getstyle(2);
          } else if (
            scrollTop > modular1 + modular2 + headertop &&
            scrollTop < modular1 + modular2 + modular3 + headertop - 1
          ) {
            this.getstyle(3);
          } else if (
            scrollTop > modular1 + modular2 + modular3 + headertop &&
            scrollTop <
              modular1 + modular2 + modular3 + modular4 + headertop - 1
          ) {
            this.getstyle(4);
          } else if (
            scrollTop > modular1 + modular2 + modular3 + modular4 + headertop &&
            scrollTop <
              modular1 +
                modular2 +
                modular3 +
                modular4 +
                modular5 +
                headertop -
                1
          ) {
            this.getstyle(5);
          } else if (
            scrollTop >
              modular1 +
                modular2 +
                modular3 +
                modular4 +
                modular5 +
                headertop &&
            scrollTop <
              modular1 +
                modular2 +
                modular3 +
                modular4 +
                modular5 +
                modular6 +
                headertop -
                1
          ) {
            this.getstyle(6);
          }
        } else if (scrollTop < headertop - 1) {
          tabbar.style.position = "absolute";
          tabbar.style.top = "0";
          tabbar.style.left = "0px";
          this.getstyle(1);
        }
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    let id = this.$route.query.ID || localStorage.getItem("page_id");
    // console.log(id);
    this.getsolutionlist(id);
    this.$eventbus.$on("page_id", (id) => {
      this.getsolutionlist(id);
      // console.log(id);
    });

    console.log(this.$route.query.ID);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";
// @import "./components/style.css";
#solution {
  position: relative;
  width: 75rem;
  min-height: 20rem;
  // background: cornsilk;
  color: black;
  margin: 0 auto;
  #tabbar {
    width: 100%;
    height: 3rem;
    z-index: 1;
    background: white;
    // background: chocolate;
    margin-top: 0.5rem;
    transition: all ease 2s;
  }
  #con_tent {
    // background: bisque;
    padding-top: 5rem;
    margin: 0 0 3rem 0;
    width: 100%;
    height: 100%;
    .column;
    justify-content: space-around;
    article {
      color: black;
      width: 100%;
      // min-height: 20rem;
    }
  }
}
</style>