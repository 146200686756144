<template>
  <div id="hotsale">
    <div id="title">
      <span>— 高性价比 —</span>
      <span>我们的大数据中心</span>
      <span>选择合适的网络托管包或致电我们寻求帮助。</span>
    </div>
    <div id="tab">
      <div class="title">
        <span
          id="title_span"
          v-for="item in title"
          :key="item.id"
          @click="tabjump(item)"
        >{{item.title}}</span>
      </div>
      <div class="content">
        <el-card
          body-style="padding:0"
          v-show="hotsale_Show"
          class="box-card animated fadeInUp"
          shadow="hover"
          v-for="item in tabcontent"
          :key="item.id"
        >
          <div class="card_top">
            <span>{{item.name}}</span>
            <span>{{item.price}}</span>
            <span>{{item.timestr}}</span>
          </div>
          <div class="card_bottom">
            <span>{{item.space}}</span>
            <span>{{item.database}}</span>
            <span>{{item.cpu}}</span>
            <span>{{item.flow}}</span>
            <span>{{item.ram}}</span>
            <el-button @click="href(item)">马上购买</el-button>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
// import logo from "../../assets/images/logo.png";
import { host } from "../../../const";
export default {
  data() {
    return {
      title: [],
      tabcontent: [],
    };
  },
  props: ["hotsale_Show"],
  methods: {
    tabjump(item) {
      // console.log(item.id);
      let url = host + "/portal/home/getdatacenterlist?id=" + item.id;
      this.$axios.get(url).then((res) => {
        this.tabcontent = res.data.data;
      });
      let span = document.querySelectorAll("#title_span");
      span.forEach((el) => {
        // console.log(el.innerHTML);
        if (el.innerHTML === item.title) {
          // console.log(el.innerHTML);
          el.style.color = "#02247d";
          el.style.borderBottom = "0.125rem solid #02247d";
        } else {
          el.style.color = "black";
          el.style.borderBottom = "none";
        }
      });
    },
    getdatacenter() {
      let url = host + "/portal/home/getdatacenter";
      return this.$axios({ url: url, method: "GET" });
    },
    getdatacenterlist() {
      let url = host + "/portal/home/getdatacenterlist";
      return this.$axios({
        url: url,
        method: "GET",
        params: {
          id: 16,
        },
      });
    },
    href(el) {
      // console.log(el);
      window.open(el.url);
    },
  },
  mounted() {
    let that = this;
    this.$axios.all([that.getdatacenter(), that.getdatacenterlist()]).then(
      that.$axios.spread((title, content) => {
        this.title = title.data.data;
        this.tabcontent = content.data.data;
        // console.log(content.data.data)
      })
    );
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/main.less";

#hotsale {
  width: 75rem;
  height: 59.75rem;
  margin: 0 auto;
  color: black;
  background: white;
  .column;
  justify-content: center;
  #title {
    .column;
    width: 100%;
    height: 10rem;
    // background: chocolate;
    justify-content: space-around;
    // margin-top: 1.25rem;
    span:nth-of-type(1) {
      .span;
      font-size: 1rem;
    }
    span:nth-of-type(2) {
      .span;
      font-size: 1.875rem;
      font-weight: 400;
    }
    span:nth-of-type(3) {
      .span;
      font-size: 1.5rem;
      font-weight: 400;
      // margin-top: 2rem;
    }
  }
  #tab {
    width: 100%;
    height: 43.75rem;
    // background: cornsilk;
    .column;
    align-items: center;
    justify-content: space-around;
    .title {
      margin-top: 2.5rem;
      width: 70%;
      height: 2.5rem;
      .row;
      justify-content: space-around;
      border-bottom: 0.125rem solid #ccc;
      span {
        padding-bottom: 20px;
      }
      span:nth-of-type(1) {
        color: @bg;
        border-bottom: 0.125rem solid @bg;
      }
    }
    .content {
      width: 70%;
      height: 100%;
      .row;
      justify-content: space-between;
      // background: olive;
      .box-card {
        transition: all ease 0.5s;
        height: 35rem;
        // height: 12.5rem;
        // width: 32rem;
        width: 12rem;
        // background: coral;
        border-radius: 1.25rem 1.25rem 0 0;
        .card_top {
          width: 100%;
          height: 12.5rem;
          background: #ccc;
          transition: all ease 2s;
          .column;
          justify-content: center;
          span:nth-of-type(2) {
            font-size: 3rem;
          }
        }
        .card_bottom {
          width: 100%;
          height: 22.625rem;
          // height: 22.625rem;
          //   background: #ccc;
          .column;
          justify-content: space-around;
        }
      }
      // .box-card:nth-of-type(2) {
      //   height: 35rem;
      //   width: 22rem;
      //   .card_top {
      //     background: #163a7a;
      //     span {
      //       color: white;
      //     }
      //   }
      //   .el-button {
      //     background: #163a7a;
      //     color: white;
      //   }
      // }
      .box-card:hover {
        height: 35rem;
        width: 22rem;
        .card_top {
          background: #163a7a;
          span {
            color: white;
          }
        }
        .el-button {
          background: #163a7a;
          color: white;
        }
      }
    }
  }
}
</style>