<template>
  <div id="bigdata">
    <Tabbar :tabbar="tabbar"></Tabbar>
    <div
      id="content"
      v-show="ConShow"
      class="animated fadeInUp"
      v-html="detail.content"
    ></div>
  </div>
</template>
<script>
import Tabbar from "./components/tabbar";
import { host } from "../../const";
export default {
  data() {
    return {
      tabbar: [],
      detail: {},
      ConShow: false,
    };
  },
  metaInfo: {
    title: "夏龙通信官网--大数据",
    meta: [
      {
        name: "keywords",
        content:
          "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入--大数据",
      },
      {
        name: "description",
        content:
          "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--大数据",
      },
    ],
  },
  components: { Tabbar },
  mounted() {
    // console.log(this.tabbar);
    let id = this.$route.query.ID || localStorage.getItem("bid");
    console.log(this.$route.query.ID, localStorage.getItem("bid"));
    this.getbigdata(id);
    this.borderBottom(id);
    this.$eventbus.$on("bid", (id) => {
      this.getbigdata(id);
      // console.log(id);
      this.borderBottom(id);
    });
  },
  methods: {
    borderBottom(id) {
      this.ConShow = false;
      setTimeout(() => {
        this.ConShow = true;
        // console.log(title);
        this.tabbar.forEach((el) => {
          if (el.bid == id) {
            // console.log(el.name);
            let title = document.querySelectorAll(
              "#bigdata #title .title span"
            );
            title.forEach((ele) => {
              // console.log(ele.innerHTML);
              if (ele.innerHTML === el.name) {
                // console.log(ele.innerHTML, el.name);
                ele.style.borderBottom = "2px solid rgba(1, 35, 124, 1)";
              } else {
                ele.style.borderBottom = "none";
              }
            });
          }
        });
      }, 200);
    },
    getbigdata(id) {
      let url = host + "/portal/home/getbigdata?id=" + id;
      this.$axios.get(url).then((res) => {
        this.ConShow = true;
        // console.log(res.data.data);
        this.tabbar = res.data.data.list;
        this.detail = res.data.data.detail;
        let banner_con = {
          // allow: "",
          title: res.data.data.detail.name,
          content: res.data.data.detail.remark,
          image: res.data.data.detail.image,
          small_image: res.data.data.detail.small_image,
          sid: id,
        };
        // console.log(banner_con);
        this.$eventbus.$emit("banner_con", banner_con);
      });
    },
  },
};
</script>
<style scoped lang="less">
// @import "./components/style.css";
#bigdata {
  width: 100%;
  min-height: 200rem;
  #content {
    width: 75rem;
    min-height: 10rem;
    margin: 50px auto;
    color: black;
  }
}
.btn-class:hover {
  background: coral;
}
li {
  list-style: none;
}
</style> 