<template>
  <div id="azure">
    <Stack v-if="url==='Stack'" />
    <Azure v-if="url==='Azure'" />
  </div>
</template>

<script>
// import logo from "../../assets/images/logo.png";
import Azure from "./azure.vue";
import Stack from "./stack.vue";

export default {
  data() {
    return {
      url: "",
    };
  },
  components: {
    Azure,
    Stack,
  },
  props: [],
  methods: {},
  mounted() {
    let type = this.$route.params.type;
    // console.log(type);
    if (type != undefined) {
      this.url = "Azure";
    } else {
      this.url = "Stack";
    }
    this.$eventbus.$on("cloudtype", (type) => {
      this.url = type;
    });
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";
#azure {
  width: 75rem;
  min-height: 50rem;
  // background: blanchedalmond;
  margin: 0 auto 10px;
}
</style>