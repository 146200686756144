<template>
  <div id="contact" v-show="ConShow" class="animated fadeInUp">
    <div class="contact">
      <div class="content">
        <el-card
          body-style="padding:0"
          class="box-card"
          shadow="hover"
          v-for="item in itemlist"
          :key="item.id"
        >
          <img :src="cartbg" :alt="item.txt" />
          <div>
            <p>
              {{item.txt}}
              <span v-for="el in item.tel" :key="el.id">{{el}}</span>
            </p>
          </div>
        </el-card>
      </div>
    </div>
    <div id="map">
      <baidu-map
        class="bm-view"
        :center="center"
        :zoom="zoom"
        @ready="handler"
        :scroll-wheel-zoom="true"
        :mapStyle="mapStyle"
      >
        <bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-scale>
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <bm-marker :position="center" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
          <bm-label
            content="广东省深圳市福田区梅坳八路深燃大厦B座911室"
            :labelStyle="{color: 'red', fontSize : '12px'}"
            :offset="{width: -100, height: 55}"
          />
        </bm-marker>
        <bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map>
        <bm-panorama anchor="BMAP_ANCHOR_TOP_LEFT"></bm-panorama>
        <!-- <bm-local-search keyword="深燃大厦夏龙通信" :auto-viewport="true" location="深圳市福田区"></bm-local-search> -->
      </baidu-map>
    </div>
    <div id="address">
      <div class="address">
        <p class="title">
          <i class="el-icon-more"></i>办公地址
        </p>
        <article>
          <section v-for="item in address" :key="item.id">
            <p class="title_p">{{item.name}}：</p>
            <aside>
              <p>地址：{{item.address}}</p>
              <p v-if="item.phone!=''">电话：{{item.phone}}</p>
              <p v-if="item.tel!=''">传真：{{item.tel}}</p>
              <p>邮编：{{item.postcode}}</p>
            </aside>
          </section>
        </article>
      </div>
    </div>
  </div>
</template>
<script>
// import Tabbar from "./components/tabbar";
import contactbg from "../../assets/images/index_map_bg.jpg";
import cartbg from "../../assets/images/index_bg_project4.jpg";
import { host } from "../../const";
export default {
  data() {
    return {
      ConShow: false,
      contactbg: contactbg,
      cartbg: cartbg,
      address: [],
      itemlist: [
        {
          txt: "售前服务",
          tel: ["139 2285 5777"],
        },
        { txt: "数据中心电话", tel: ["0755-88824600"] },
        // { txt: "网管中心：", tel: "" },
        { txt: "公司总机", tel: ["0755-33229777"] },
      ],
      center: { lng: 114.064561, lat: 22.579382 },
      zoom: 20,
      mapStyle: {
        styleJson: [
          {
            featureType: "land",
            elementType: "geometry",
            stylers: {
              color: "#e7f7fc",
            },
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: {
              color: "#96b5d6",
            },
          },
          {
            featureType: "green",
            elementType: "all",
            stylers: {
              color: "#b0d3dd",
            },
          },
          {
            featureType: "highway",
            elementType: "geometry.fill",
            stylers: {
              color: "#a6cfcf",
            },
          },
          {
            featureType: "highway",
            elementType: "geometry.stroke",
            stylers: {
              color: "#7dabb3",
            },
          },
          {
            featureType: "arterial",
            elementType: "geometry.fill",
            stylers: {
              color: "#e7f7fc",
            },
          },
          {
            featureType: "arterial",
            elementType: "geometry.stroke",
            stylers: {
              color: "#b0d5d4",
            },
          },
          {
            featureType: "local",
            elementType: "labels.text.fill",
            stylers: {
              color: "#7a959a",
            },
          },
          {
            featureType: "local",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#d6e4e5",
            },
          },
          {
            featureType: "arterial",
            elementType: "labels.text.fill",
            stylers: {
              color: "#374a46",
            },
          },
          {
            featureType: "highway",
            elementType: "labels.text.fill",
            stylers: {
              color: "#374a46",
            },
          },
          {
            featureType: "highway",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#e9eeed",
            },
          },
        ],
      },
    };
  },
  metaInfo: {
    title: "夏龙通信官网--联系我们",
    meta: [
      {
        name: "keywords",
        content:
          "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入--联系我们",
      },
      {
        name: "description",
        content:
          "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--联系我们",
      },
    ],
  },
  components: {},
  mounted() {
    this.ConShow = true;
    this.$eventbus.$emit("banner_id", 16);
    localStorage.setItem("banner_id", 16);
    this.getofficeaddress();
  },
  methods: {
    getofficeaddress() {
      let url = host + "/portal/home/getofficeaddress";
      this.$axios.get(url).then((res) => {
        // console.log("办公地址", res.data.data);
        this.address = res.data.data;
      });
    },
    handler({ BMap, map }) {
      console.log(BMap, map);
      this.center.lng = 114.064561;
      this.center.lat = 22.579382;
      this.zoom = 20;
    },
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";
#contact {
  width: 100%;
  min-height: 50rem;
  //   background: blanchedalmond;
  .column;
  justify-content: center;
  .contact {
    width: 100%;
    height: 12rem;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    .row;
    justify-content: center;
    .content {
      width: 75rem;
      height: 12rem;
      // background: cadetblue;
      .row;
      justify-content: space-between;
      .box-card {
        position: relative;
        top: 20px;
        z-index: 0;
        width: 30%;
        height: 100%;
        // background: burlywood;
        img {
          width: 20%;
          height: 20%;
          float: left;
          margin: 10% 0 0 5%;
        }
        div {
          float: right;
          width: 60%;
          height: 10rem;
          margin: 7.5% 5%;
          //   background: cornsilk;
          p {
            .column;
            align-items: flex-start;
            justify-content: center;
            font-weight: 600;
          }
          p:nth-of-type(1) {
            font-size: 1.5rem;
            span {
              margin-top: 5px;
              font-size: 1rem;
              font-weight: 100;
            }
          }
        }
      }
    }
  }
  #map {
    width: 75rem;
    height: 35rem;
    margin: 2rem 0 5rem;
    // background: #ccc;
    // img {
    //   width: 100%;
    //   background-size: cover;
    // }
    .bm-view {
      width: 100%;
      height: 100%;
    }
  }
  #address {
    width: 100%;
    height: 20rem;
    // background: cornflowerblue;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    .row;
    justify-content: center;
    .address {
      width: 75rem;
      height: 90%;
      // background: cornsilk;
      color: black;
      .title {
        width: 100%;
        height: 3rem;
        // background: burlywood;
        .column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 1.25rem;
        font-weight: bolder;
      }
      article {
        width: 100%;
        height: 70%;
        // background: burlywood;
        .row;
        justify-content: space-between;
        section {
          width: 45%;
          height: 100%;
          // background: cadetblue;
          .row;
          justify-content: space-between;
          align-items: flex-start;
          .title_p {
            color: @bg;
            font-weight: bolder;
          }
          p {
            color: #666666;
          }
        }
      }
    }
  }
}
</style> 