<template>
  <div id="bigdata">
    <Content v-if="href==='Content'" />
  </div>
</template>
<script>
import Content from "./content";
export default {
  data() {
    return {
      href: "Content"
    };
  },
  components: {
    Content
  },
  mounted() {
    this.$eventbus.$on("title", title => {
      // console.log(title);
      this.href = title;
    });
    let type = this.$route.params.type;
    // console.log(this.$route.params);
    if (type != undefined) {
      this.href = type;
    }
  },
  methods: {}
};
</script>
<style scoped lang="less">
#bigdata {
  width: 100%;
  min-height: 20rem;
}
</style> 