<template>
  <div id="user">
    <header>
      <div id="logo" @click="linkTo('/')">
        <img :src="logo" class="logo" alt="logo" />
        <p>夏龍通信</p>
      </div>
      <Nav />
      <div id="internationalization">
        <p>EN / 中 / 繁</p>
      </div>
    </header>
    <article>
      <p>欢迎{{applyName}}</p>
      <section>
        <img :src="logo" alt="logo" />
        <aside>
          <form @submit.prevent="submitFrom">
            <ul>
              <li>
                <span>姓名</span>
                <input type="text" v-model="formData.name" @blur="blur_name" />
                <i class="el-icon-success" style="color:#ff004e" v-show="show_name"></i>
              </li>
              <li>
                <span>QQ</span>
                <input type="text" v-model="formData.qq" @blur="blur_qq" />
                <i class="el-icon-success" style="color:#ff004e" v-show="show_qq"></i>
              </li>
              <li>
                <span>公司</span>
                <input type="text" v-model="formData.company" @blur="blur_company" />
                <i class="el-icon-success" style="color:#ff004e" v-show="show_company"></i>
              </li>
              <li>
                <span>手机号</span>
                <input type="text" maxlength="11" v-model="formData.phone" @blur="blur_phone" />
                <i class="el-icon-success" style="color:#ff004e" v-show="show_phone"></i>
              </li>
              <li>
                <span>邮箱</span>
                <input type="email" v-model="formData.email" @blur="blur_email" />
                <i class="el-icon-success" style="color:#ff004e" v-show="show_email"></i>
              </li>
              <li>
                <span>
                  <input type="text" maxlength="6" v-model="telcode" placeholder="请输入验证码" />
                </span>
                <button @click="code" class="codeText">{{coedText}}</button>
              </li>
              <li>
                <span>用途</span>
                <input type="text" v-model="formData.use" @blur="blur_use" />
                <i class="el-icon-success" style="color:#ff004e" v-show="show_use"></i>
              </li>
              <li>
                <button type="submit">立即{{applyName}}</button>
              </li>
            </ul>
          </form>
        </aside>
      </section>
    </article>
    <article>
      <p>版权所有&copy;广东夏龙通信有限公司 粤ICP备08100026号-2 经营许可证:B1.B2-20130223 / 粤B1.B2-20070469</p>
      <p>
        <img :src="copy1" alt="网监" />
        <img :src="copy2" alt="网监" />粤公网安备：44030401000101
      </p>
    </article>
  </div>
</template>

<script>
import logo from "../../assets/images/logo.png";
import apply_bg1 from "../../assets/images/apply_bg1.png";
import copy1 from "../../assets/images/copy1.png";
import copy2 from "../../assets/images/copy2.gif";
import Nav from "../../components/nav/index.vue";
import { host } from "../../const";
export default {
  data() {
    return {
      codeflag: false,
      applyName: "",
      copy1: copy1,
      copy2: copy2,
      logo: logo,
      apply_bg1: apply_bg1,
      formData: {
        name: "",
        phone: "",
        qq: "",
        company: "",
        email: "",
        use: "",
      },
      telcode: "",
      show_name: false,
      show_phone: false,
      show_qq: false,
      show_company: false,
      show_email: false,
      show_use: false,
      sid: 0,
      apply_type: 0,
      coedText: "发送验证码",
      timer: "",
    };
  },
  metaInfo: {
    title: "夏龙通信官网--申请体验",
    meta: [
      {
        name: "keywords",
        content:
          "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入--申请体验",
      },
      {
        name: "description",
        content:
          "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--申请体验",
      },
    ],
  },
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      // alert(`sid=${vm.sid}`);
      // let that=this;
      if (vm.sid == null) {
        alert(`您还没有选择解决方案哟！`);
        next("/");
      }
    });
  },
  components: { Nav },
  methods: {
    linkTo(url) {
      //点击跳转
      this.$router.push(url);
    },
    blur_name() {
      // let re_name = /^[a-zA-Z0-9_-]{2,20}$/;
      // if (re_name.test(this.formData.name)) {
      if (this.formData.name != "") {
        this.show_name = true;
      } else {
        this.show_name = false;
        this.$message({
          message: "您的输入有误",
          type: "warning",
        });
      }
    },
    blur_qq() {
      let re_qq = /^[0-9]{6,20}$/;
      if (re_qq.test(this.formData.qq)) {
        this.show_qq = true;
      } else {
        this.show_qq = false;
        this.$message({
          message: "您的输入有误",
          type: "warning",
        });
      }
    },
    blur_company() {
      if (this.formData.company != "") {
        this.show_company = true;
      } else {
        this.show_company = false;
        this.$message({
          message: "您的输入不能为空",
          type: "warning",
        });
      }
    },
    blur_phone() {
      let re_phone = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (re_phone.test(this.formData.phone)) {
        this.show_phone = true;
      } else {
        this.show_phone = false;
        this.$message({
          message: "您的输入有误",
          type: "warning",
        });
      }
    },
    blur_email() {
      if (this.formData.email != "") {
        this.show_email = true;
      } else {
        this.show_email = false;
        this.$message({
          message: "您的输入不能为空",
          type: "warning",
        });
      }
    },
    blur_use() {
      if (this.formData.use != "") {
        this.show_use = true;
      } else {
        this.show_use = false;
        this.$message({
          message: "您的输入不能为空",
          type: "warning",
        });
      }
    },
    blur_code() {
      if (!this.telcode) {
        clearTimeout(this.timer);
        this.coedText = "再次发送验证码";
      } else {
        this.coedText = "发送验证码";
      }
    },
    code() {
      var codeText = document.querySelector(".codeText");
      if (this.formData.phone != "") {
        let data = {
          phone: this.formData.phone,
          sid: this.sid,
        };
        let url = host + "/portal/home/getsmscode";
        this.$axios.post(url, data).then((res) => {
          // console.log(res.data.data);
          if (res.data.msg == "短信验证码发送成功，请注意查收") {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            var num = 60;
            this.timer = setInterval(() => {
              num--;
              this.coedText = num + "秒";
              codeText.style.background = "#ccc";
              codeText.disabled = true;
              if (num == 0) {
                codeText.style.background = "#105eb4";
                codeText.disabled = false;
                clearTimeout(this.timer);
                this.blur_code();
              }
            }, 1000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      } else {
        this.show_phone = false;
        this.$message({
          message: "请先输入手机号",
          type: "warning",
        });
      }
    },
    submitFrom() {
      if (
        this.formData.name != "" ||
        this.formData.phone != "" ||
        this.formData.qq != "" ||
        this.formData.company != "" ||
        this.formData.email != "" ||
        this.formData.use != "" ||
        this.telcode != ""
      ) {
        // console.log(this.formData);
        let data = {
          name: this.formData.name,
          phone: this.formData.phone,
          sid: this.sid,
          qq: this.formData.qq,
          company: this.formData.company,
          email: this.formData.email,
          use: this.formData.use,
          code: this.telcode,
          type: this.apply_type,
        };
        // console.log(data);
        let url = host + "/portal/home/application";
        this.$axios.post(url, data).then((res) => {
          if (
            res.data.msg ==
              "恭喜您申请成功！待我们审核之后会发送短信到你的手机" ||
            res.data.msg == "咨询成功，我们将会与您联系"
          ) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            setTimeout(() => {
              localStorage.setItem("page_id", this.sid);
              this.$router.push("/solution");
              this.$eventbus.$emit("page_id", this.sid);
            }, 1000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
          // console.log(res.data.data);
        });
      } else {
        this.$message({
          message: "您的输入不能为空",
          type: "warning",
        });
      }
    },
  },
  mounted() {
    console.log(this.$route.params);
    this.sid = this.$route.params.id;
    let Name = localStorage.getItem("applyName");
    this.applyName = Name;
    this.sid = localStorage.getItem("page_id");
    if (Name == "申请") {
      this.apply_type = "1";
    } else if (Name == "咨询") {
      this.apply_type = "0";
    }
    console.log(this.apply_type);
    console.log(this.applyName, this.sid);
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";
#user {
  width: 100%;
  height: 60rem;
  background: rgba(0, 0, 0, 0.8) url("~@/assets/images/apply_bg.png") no-repeat;
  background-size: cover;
  .column;
  justify-content: space-between;
  header {
    width: 75rem;
    height: 4.0625rem;
    .row;
    justify-content: space-between;
    padding: 2rem 0;
    #logo {
      width: 15%;
      height: 100%;
      .row;
      justify-content: center;
      img {
        width: 3rem;
      }
      p {
        width: 8.875rem;
        font-size: 1.25rem;
        color: white;
        font-weight: 400;
        margin-left: 10%;
      }
    }
  }
  article:nth-of-type(1) {
    width: 75rem;
    height: 31.25rem;
    background: url("~@/assets/images/apply_bg1.png");
    .column;
    justify-content: space-around;
    p {
      font-size: 1.5rem;
      font-weight: bolder;
    }
    section {
      width: 90%;
      height: 80%;
      // background: coral;
      .row;
      justify-content: space-between;
      aside {
        width: 70%;
        height: 90%;
        // background: darkcyan;
        form {
          width: 100%;
          height: 100%;
          // background: darkgray;
          ul {
            width: 100%;
            height: 100%;
            // background: darkkhaki;
            .row;
            justify-content: space-around;
            flex-wrap: wrap;
            li {
              width: 19.8125rem;
              height: 3.0625rem;
              background: url("~@/assets/images/apply_bg2.png");
              .row;
              justify-content: flex-start;
              span {
                width: 3.125rem;
                margin: 0 0.5rem;
              }
              input {
                width: 13rem;
                height: 80%;
                font-size: 1rem;
                border: none;
                background: none;
                // background: orange;
                outline: none;
                color: white;
              }
              i {
                margin-left: 1rem;
              }
            }
            li:nth-of-type(odd) {
              margin-left: -5rem;
            }
            li:nth-last-of-type(3) {
              background: none;
              .row;
              justify-content: space-between;
              // background: orange;
              span {
                width: 50%;
                height: 100%;
                .row;
                justify-content: center;
              }
              span:nth-of-type(1) {
                background: url("~@/assets/images/apply_bg2.png");
                // background-size:contain ;
                // background: oldlace;
                input {
                  width: 75%;
                  height: 90%;
                  // background: olivedrab;
                }
                .placeholder;
              }
              button {
                width: 150px;
                height: 50px;
                color: white;
                border: none;
                background: #105eb4;
              }
            }
            li:nth-last-of-type(1) {
              button {
                width: 100%;
                height: 100%;
                border: none;
                background: #5384d9;
                outline: none;
                color: white;
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
  article:nth-of-type(2) {
    width: 75rem;
    height: 10rem;
    // background: wheat;
    border-top: 1px solid #999;
    p:nth-of-type(2) {
      // background: bisque;
      .row;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 1.25rem;
        margin-right: 0.625rem;
      }
      img:nth-of-type(2) {
        padding-top: 0.3125rem;
      }
    }
  }
}
</style>