<template>
  <div id="partners">
    <span id="title">合作伙伴</span>

    <div id="images">
      <el-carousel :interval="4000" type="card" height="300px">
        <el-carousel-item v-for="item in partner" :key="item.id">
          <a class="hvr-pop">
            <img :src="item.image" :alt="item.title" />
          </a>
          <a class="hvr-pop">
            <img :src="item.image1" :alt="item.title" />
          </a>
        </el-carousel-item>
      </el-carousel>
      <!-- <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>Slide 1</swiper-slide>
        <swiper-slide>Slide 2</swiper-slide>
        <swiper-slide>Slide 3</swiper-slide>
        <swiper-slide>Slide 4</swiper-slide>
        <swiper-slide>Slide 5</swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper> -->
    </div>
  </div>
</template>

<script>
// import logo from "../../assets/images/logo.png";
export default {
  data() {
    return {
      partnersList: [],
      // swiperOptions: {
      //   pagination: {
      //     el: ".swiper-pagination"
      //   }
      // }
    };
  },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.$swiper;
    // }
  },
  props: ["partner"],
  methods: {},
  mounted() {
    // console.log("Current Swiper instance object", this.swiper);
    // this.swiper.slideTo(3, 1000, false);
  }
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";

#partners {
  width: 75rem;
  height: 30rem;
  margin: 0 auto;
  color: black;
  // background: beige;
  .column;
  align-items: center;
  justify-content: space-around;
  #title {
    width: 100%;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin-top: 2rem;
  }
  #images {
    width: 95%;
    height: 60%;
  }
}

.el-carousel__item {
  .column;
  justify-content: space-around;
  // background: oldlace;
}
</style>