<template>
  <div id="item">
    <Tabbar :tabbar="tabbar" />
    <div class="news">
      <div class="content">
        <el-card
          body-style="padding:0"
          class="box-card"
          shadow="hover"
          v-for="item in card_content"
          :key="item.id"
        >
          <div @click="linkto(item.id)">
            <img
              :src="item.image"
              width="100%"
              height="200"
              :alt="item.title"
            />
            <p>
              <strong>{{ item.title }}</strong>
              <!-- {{item.title}} -->
              <span>{{ item.remark }}</span>
            </p>
            <span class="timer">[{{ item.add_time }}]</span>
          </div>
        </el-card>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :hide-on-single-page="value"
        :total="count"
        :page-size="page_size"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import newsbg from "../../../assets/images/news.jpg";
import Tabbar from "./tabbar";
import { host } from "../../../const";
export default {
  data() {
    return {
      newsbg: newsbg,
      value: false,
      tabbar: [],
      card_content: [],
      ID: 1,
      current: 1,
      page_size: 9,
      count: 9,
      target: 0,
    };
  },
  components: {
    Tabbar,
  },
  props: [],
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.getconsultationlist(this.ID, val);

      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 10);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    linkto(id) {
      // this.$eventbus.$emit("news_id", id);
      this.$eventbus.$emit("news_url", "Content");
      localStorage.setItem("news_url", "Content");
      localStorage.setItem("news_id", id);
      this.$router.push({
        path: "/about/News",
        query: { news_url: "Content", news_id: id },
      });
    },
    getaboutnewscenter() {
      let url = host + "/portal/home/getaboutnewscenter";
      this.$axios.get(url).then((res) => {
        // console.log("新闻中心", res.data.data);
        this.tabbar = res.data.data;
        // console.log(this.tabbar[0].id);
      });
    },
    //新闻中心列表
    getconsultationlist(id, page) {
      let url =
        host + "/portal/home/getaboutnewslist?id=" + id + "&page=" + page;
      this.$axios.get(url).then((res) => {
        // console.log("新闻中心列表", res.data.data);
        // this.tabbar = res.data.data;
        this.card_content = res.data.data.item;
        this.page_size = res.data.data.num;
        this.count = res.data.data.count;
      });
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    this.getaboutnewscenter();

    this.$eventbus.$on("tabbar_id", (id) => {
      // console.log(id);
      this.ID = id;
      this.getconsultationlist(id, 1);
    });

    let tabbar_id = localStorage.getItem("tabbar_id");
    this.ID = tabbar_id;
    // console.log(tabbar_id);
    if (tabbar_id != null) {
      // console.log(tabbar_id);
      this.getconsultationlist(tabbar_id, 1);
    } else {
      setTimeout(() => {
        // console.log(this.tabbar[0].id);
        let id = this.tabbar[0].id;
        this.getconsultationlist(id, 1);
      }, 500);
    }
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/main.less";
#item {
  width: 100%;
  min-height: 20rem;
  .news {
    margin-top: 3rem;
    width: 100%;
    min-height: 30rem;
    // background: blanchedalmond;
    .column;
    justify-content: space-between;
    .content {
      width: 75rem;
      min-height: 30rem;
      // background: cadetblue;
      .row;
      flex-wrap: wrap;
      justify-content: flex-start;
      .box-card {
        width: 30%;
        height: 25rem;
        // background: brown;
        margin: 3.125rem 19px 0;
        p {
          width: 90%;
          height: 9rem;
          // background: coral;
          margin: 1.5% auto;
          .column;
          justify-content: flex-start;
          align-items: flex-start;
          border-bottom: 1px solid #eee;
          font-weight: bolder;
          strong {
            width: 100%;
            // background: wheat;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          span {
            margin: 2% 0;
            font-weight: normal;
            text-align: justify;
            width: 100%;
            height: 5rem;
            // background: coral;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        .timer {
          margin: 0 auto;
          // margin-top: 5%;
          width: 90%;
          height: 2rem;
          .row;
          justify-content: flex-start;
          // background: cadetblue;
          // margin-left: 5%;
        }
      }
      .box-card:nth-of-type(1),
      .box-card:nth-of-type(2),
      .box-card:nth-of-type(3) {
        margin-top: 0;
      }
    }
    .el-pagination {
      margin: 3.125rem;
    }
  }
}
</style>