<template>
  <div id="content">
    <article>
      <p class="title">
        <strong>新闻资讯</strong>
        <span @click="linkto">返回列表</span>
      </p>
      <p class="detail_title">
        <strong>{{ detail.title }}</strong>
        <span>
          <em>
            发稿时间:
            <i>{{ detail.add_time }}</i>
          </em>
          <em>
            来源:
            <i>{{ detail.author }}</i>
          </em>
        </span>
      </p>
      <section v-html="detail.content"></section>
      <section>
        <p v-if="previous_flag">
          上一篇:
          <span @click="news_jump(previous.id)">{{
            previous_flag ? previous.title : "已经到顶啦"
          }}</span>
        </p>
        <p v-if="next_flag">
          下一篇:
          <span @click="news_jump(next.id)">{{
            next_flag ? next.title : "已经到底啦"
          }}</span>
        </p>
      </section>
    </article>
    <article>
      <section>
        <p class="title_p">
          <strong>最新内容</strong>
          <i class="fa fa-free-code-camp"></i>
        </p>
        <!-- <aside>
          <img :src="new_content[0].image" @click="news_jump(new_content[0].id)" alt />
          <img :src="new_content[1].image" @click="news_jump(new_content[1].id)" alt />
        </aside>-->
        <ul>
          <li
            v-for="item in new_content"
            :key="item.id"
            @click="news_jump(item.id)"
          >
            <span>
              <em>{{ item.title }}</em>
            </span>
            <span>{{ item.update_time }}</span>
          </li>
        </ul>
      </section>
      <section>
        <p class="title_p">
          <strong>热点内容</strong>
          <i class="fa fa-free-code-camp"></i>
        </p>
        <aside>
          <p
            v-for="item in new_content"
            :key="item.id"
            @click="news_jump(item.id)"
          >
            <img :src="item.image" :alt="item.title" />
            <span>
              <strong>{{ item.title }}</strong>
              {{ item.update_time }}
            </span>
          </p>
        </aside>
      </section>
    </article>
  </div>
</template>

<script>
import news from "../../../assets/images/news_content.jpg";
import { host } from "../../../const";
export default {
  data() {
    return {
      news: news,
      detail: {},
      next: {},
      previous: {},
      previous_flag: true,
      next_flag: true,
      hot_content: [],
      new_content: [],
      ID: 1,
    };
  },
  components: {
    // Header
  },
  props: [],
  methods: {
    getaboutnewsdetail(id) {
      let url = host + "/portal/home/getaboutnewsdetail?id=" + id;
      this.$axios.get(url).then((res) => {
        console.log(res.data.data);
        this.detail = res.data.data.detail;
        this.next = res.data.data.next;
        this.previous = res.data.data.previous;
        // console.log(res.data.data.detail);
        if (res.data.data.previous.id == undefined) {
          this.previous_flag = false;
        } else {
          this.previous_flag = true;
        }
        if (res.data.data.next.id == undefined) {
          this.next_flag = false;
        } else {
          this.next_flag = true;
        }
        // console.log("下一", res.data.data.next.title, res.data.data.next.id);

        // console.log(
        //   "上一",
        //   res.data.data.previous.title,
        //   res.data.data.previous.id
        // );
      });
    },
    getaboutrecommendnews() {
      let url = host + "/portal/home/getaboutrecommendnews";
      this.$axios.get(url).then((res) => {
        // console.log(res.data.data.new);
        this.hot_content = res.data.data.hot;
        this.new_content = res.data.data.new;
      });
    },
    linkto() {
      this.$router.push({
        path: "/about/News",
        query: { news_url: "Item" },
      });
      this.$eventbus.$emit("news_url", "Item");
      localStorage.setItem("news_url", "Item");
    },
    news_jump(id) {
      //切换
      this.getaboutnewsdetail(id);
    },
    getaboutnewsdetails(id) {
      // console.log(id);
      return this.$axios({
        url: host + "/portal/home/getaboutnewsdetail?id=" + id,
        method: "GET",
      });
    },
  },
  mounted() {
    let news_id = localStorage.getItem("news_id");
    this.getaboutnewsdetail(news_id);
    this.getaboutrecommendnews();
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/main.less";
#content {
  width: 75rem;
  min-height: 50rem;
  //   background: blanchedalmond;
  .row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 3.125rem 0;
  color: black;
  article:nth-of-type(1) {
    width: 72.5%;
    min-height: 20rem;
    // background: cadetblue;
    .column;
    justify-content: space-between;
    .title {
      width: 100%;
      height: 6.25rem;
      border-bottom: 0.0625rem solid black;
      .row;
      justify-content: space-between;
      strong {
        font-size: 1.25rem;
        color: @bg;
      }
      strong::before {
        content: "";
        border-left: 5px solid @bg;
        margin-right: 8px;
      }
      span {
        color: #ccc;
      }
    }
    .detail_title {
      width: 100%;
      height: 8rem;
      // background: chocolate;
      .column;
      justify-content: space-around;
      span {
        color: #ccc;
        width: 80%;
        padding: 10px 0;
        border-top: 1px dashed #ccc;
        border-bottom: 1px dashed #ccc;
        .row;
        justify-content: space-around;
        em {
          font-style: normal;
          i {
            font-style: normal;
            margin-left: 10px;
          }
        }
      }
    }
    section:nth-of-type(1) {
      width: 100%;
      min-height: 10rem;
      // background: blanchedalmond;
      .column;
      justify-content: center;
    }
    section:nth-of-type(2) {
      width: 100%;
      height: 8%;
      // background: bisque;
      .column;
      justify-content: center;
      align-items: flex-start;
      p {
        width: 100%;
        height: 3rem;
        // background: burlywood;
        .row;
        justify-content: flex-start;
        span {
          margin-left: 1rem;
          width: 90%;
          height: 100%;
          // background: brown;
          .row;
          justify-content: flex-start;
        }
      }
      p:hover {
        span {
          color: @bg;
          font-weight: bolder;
        }
      }
    }
  }
  article:nth-of-type(2) {
    width: 25%;
    height: 80rem;
    // background: bisque;
    .column;
    justify-content: space-between;
    section:nth-of-type(1) {
      width: 100%;
      height: 31.25rem;
      border: 1px solid #ccc;
      //   background: burlywood;
      .column;
      justify-content: space-between;
      .title_p {
        width: 100%;
        height: 3.375rem;
        background: #f8f8f8;
        margin: 0;
        .row;
        justify-content: flex-start;
        strong::before {
          content: "";
          border-left: 3px solid @bg;
          margin-right: 8px;
        }
      }
      aside {
        width: 95%;
        height: 15%;
        background: coral;
        .row;
        justify-content: space-between;
        img {
          width: 49%;
        }
      }
      ul {
        width: 86%;
        height: 90%;
        // background: chocolate;
        // padding: 0;
        margin: 0;
        .column;
        justify-content: space-around;
        align-items: flex-start;
        li {
          margin-left: -20px;
          list-style: circle inside;
          width: 100%;
          height: 2rem;
          // background: cornflowerblue;
          font-size: 0.75rem;
          .row;
          justify-content: space-between;
          span:nth-of-type(1) {
            display: block;
            width: 60%;
            // background: burlywood;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            em {
              // width: 90%;
              // display: block;
              font-style: normal;
            }
          }
          span:nth-of-type(2) {
            display: block;
            width: 35%;
            // background: chocolate;
          }
        }
        li:hover {
          color: @bg;
          font-weight: bolder;
        }
      }
    }
    section:nth-of-type(2) {
      width: 100%;
      height: 46.875rem;
      border: 1px solid #ccc;
      .column;
      justify-content: space-between;
      //   background: darkcyan;
      .title_p {
        width: 100%;
        height: 3.375rem;
        background: #f8f8f8;
        margin: 0;
        .row;
        justify-content: flex-start;
        strong::before {
          content: "";
          border-left: 3px solid @bg;
          margin-right: 8px;
        }
      }
      aside {
        width: 100%;
        height: 92%;
        // background: darkgoldenrod;
        p {
          width: 98%;
          height: 10%;
          margin: 0 auto;
          //   background: darkkhaki;
          .row;
          justify-content: space-between;
          img {
            width: 30%;
          }
          span {
            display: block;
            width: 63%;
            height: 100%;
            // background: coral;
            font-size: 0.75rem;
            .column;
            justify-content: center;
            align-items: flex-start;
            padding-left: 0.3125rem;
            strong {
              width: 98%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        p:hover {
          background: #ccc;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
</style>