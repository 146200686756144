<template>
  <div id="tabbar">
    <div id="title">
      <div
        class="title animated fadeInRightBig"
        v-show="tabbar_show"
        v-for="item in tabbar"
        :key="item.id"
      >
        <span @click="taggle(item)" :data="item.bid">{{item.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabbar_show: false,
    };
  },
  props: ["tabbar"],
  methods: {
    taggle(item) {
      // console.log(item);
      localStorage.setItem("bid", item.bid);
      this.$eventbus.$emit("bid", item.bid);
      this.$router.push({path:"/bigdata/Content",query: { ID: item.bid } });
      let title = document.querySelectorAll(".title span");
      title.forEach((el) => {
        // console.log(el.innerHTML, item.name);
        if (el.innerHTML === item.name) {
          el.style.borderBottom = "2px solid rgba(1, 35, 124, 1)";
        } else {
          el.style.borderBottom = "none";
        }
      });
    },
  },
  mounted() {
    // console.log(document.querySelectorAll(".title span"));
    this.tabbar_show = false;
    setTimeout(() => {
      this.tabbar_show = true;
    }, 500);
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/main.less";
#tabbar {
  width: 100%;
  height: 5.375rem;
  background: white;
  box-shadow: 2px 2px 5px #ccc;
  #title {
    width: 50%;
    height: 100%;
    margin: 0 auto;
    // background: orange;
    color: black;
    .row;
    justify-content: center;
    .title {
      width: 100%;
      height: 100%;
      // background: blanchedalmond;
      .row;
      justify-content: space-around;
      color: black;
      span {
        padding: 10px 0;
      }
    }
    // .title:nth-of-type(1) {
    //   span {
    //     border-bottom: 2px solid @bg;
    //   }
    // }
  }
}
</style>