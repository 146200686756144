<template>
  <!-- @mouseenter="enter(item.id)" -->
  <div id="product_content" @mouseenter="enter(conlist)">
    <div class="product_content">
      <section>
        <strong>{{product_name}}</strong>
        <div>
          <aside class="content">
            <p v-for="item in content.productItemList" :key="item.id">
              <!--target="_blank" :href="item.url"  -->
              <a @click="href(item)">{{item.title}}</a>
              <span>{{item.description}}</span>
            </p>
          </aside>
        </div>
      </section>
      <section>
        <strong>精品活动</strong>
        <aside>
          <a :href="ele.url" target="_blank" v-for="ele in content.productActive" :key="ele.id">
            <img :src="ele.image" :alt="ele.title" width="200" height="100" />
          </a>
        </aside>
      </section>
    </div>
  </div>
</template>

<script>
import left from "../../../../assets/images/banner_left.jpg";
// import { host } from "../../../../const";
export default {
  data() {
    return {
      ID: 0,
      left: left,
      conLists: [],
      imglist: [],
      product_name: "",
      conlist: {},
    };
  },
  props: ["content"],
  created() {},
  methods: {
    enter(conlist) {
      console.log(conlist.name);
      // this.$eventbus.$emit("product_con", conlist);
    },
    href(item) {
      console.log(item);
      let local = "https://cloud.cdt.cc/";
      if (item.sid != 0) {
        this.$router.push({ path: "/solution", query: { ID: item.sid } });
        this.$eventbus.$emit("page_id", item.sid);
        localStorage.setItem("page_id", item.sid);
        document.querySelectorAll("#menu_nav li").forEach((el) => {
          el.style.borderBottom = "0";
        });
        document.querySelector(
          "#menu_nav li:nth-of-type(4)"
        ).style.borderBottom = "1px solid white";
      } else if (item.bid != 0) {
        this.$router.push({
          path: "/bigdata/Content",
          query: { ID: item.bid },
        });
        localStorage.setItem("bid", item.bid);
        this.$eventbus.$emit("bid", item.bid);
        this.$eventbus.$emit("title", "Content");
        document.querySelectorAll("#menu_nav li").forEach((el) => {
          el.style.borderBottom = "0";
        });
        document.querySelector(
          "#menu_nav li:nth-of-type(3)"
        ).style.borderBottom = "1px solid white";
      } else {
        if (item.url.substring(0, 21) == local) {
          window.open(item.url);
        } else {
          this.$router.push(item.url);
        }
      }
    },
  },
  beforeMount() {},
  mounted() {
    this.$eventbus.$on("product_content", (content) => {
      this.conlist = content[0];
      this.product_name = content[0].name;
      // console.log(content);
    });
    // console.log(this.content);
  },
};
</script>
<style scoped lang="less">
@import "../../../../assets/css/main.less";
#product_content {
  width: 56.25rem;
  height: 37.5rem;
  background: white;
  color: black;
  .row;
  justify-content: center;
  .product_content {
    width: 90%;
    height: 90%;
    // background: #ccc;
    .row;
    justify-content: center;
    section:nth-of-type(1) {
      width: 70%;
      height: 100%;
      //background: blanchedalmond;
      .column;
      align-items: flex-start;
      justify-content: space-between;
      div {
        .row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        // background: cornsilk;
        aside {
          width: 49%;
          height: 100%;
          // background: chocolate;
          margin: 0.625rem 0;
          p {
            .column;
            align-items: flex-start;
            justify-content: flex-start;
            // background: beige;
            color: black;
            a {
              text-decoration: none;
              color: black;
            }
            a:hover {
              color: red;
            }
            span {
              padding: 0.3125rem 0;
              font-size: 0.625rem;
              color: #999;
            }
          }
        }
      }
    }
    section:nth-of-type(2) {
      width: 30%;
      height: 100%;
      //background: burlywood;
      aside {
        // background: chocolate;
        height: 50%;
        width: 100%;
        .column;
        justify-content: space-around;
        align-items: flex-start;
      }
    }
  }
}
</style>