<template>
  <div class="container">
    <Header id="header" v-if="$route.meta.header" />
    <Content id="content" v-if="isRouterAlive" />
    <Footer v-if="$route.meta.footer" />
    <Backtop />
    <!-- <TheDiv /> -->
  </div>
</template>

<script>
import Header from "./components/header/index";
import Content from "./components/content/index";
import Footer from "./components/footer/index";
import Backtop from "./components/backtop/index";
// import TheDiv from "./components/thediv/index";
export default {
  name: "app",
  data() {
    return {
      isRouterAlive: true,
    };
  },
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  components: {
    Header,
    Content,
    Footer,
    Backtop,
    // TheDiv
  },
  mounted() {},
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
  },
};
</script>

<style lang="less">
@import "assets/css/main.less";

body,
html {
  margin: 0;
  padding: 0;
}

.container {
  font-family: "webfont" !important;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: default;
  #header {
    position: relative;
    header {
      position: absolute;
      top: 0;
      transition: all ease-in-out 1s;
    }
  }
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 0.45rem;
}
/*定义滚动条轨道*/
::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  background: #999;
  // background-color: #0093ff;
  // background-image: -webkit-linear-gradient(
  //   45deg,
  //   rgba(255, 255, 255, 0.4) 25%,
  //   transparent 25%,
  //   transparent 50%,
  //   rgba(255, 255, 255, 0.4) 50%,
  //   rgba(255, 255, 255, 0.4) 75%,
  //   transparent 75%,
  //   transparent
  // );
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
</style>
