<template>
  <div id="azure" v-show="ConShow" class="animated fadeInUp">
    <div class="azupart1 minwd zprtow">
      <div class="wrap">
        <div class="title wow fadeInDown">
          <h1>Microsoft Azure 将 IT 服务变得像用电一样简单</h1>
        </div>
        <div class="contm pr wow rollIn">
          <div class="zprt zprt1 wow zoomInDown" data-wow-delay="0.6s">
            随时随地
            <br />调用资源
          </div>
          <div class="zprt zprt2 wow zoomInDown" data-wow-delay=".8s">
            用完即释，
            <br />避免浪费
          </div>
          <div class="zprt zprt3 wow zoomInDown" data-wow-delay="1s">
            集中管理，
            <br />节约成本
          </div>
          <div class="zprt zprt4 wow zoomInDown" data-wow-delay="1.2s">
            多功能集成，
            <br />兼顾企业不同需求
          </div>
        </div>
        <div class="btn tzform wow fadeInUp" style="position: relative;">
          <a
            href="http://p.qiao.baidu.com/cps/chat?siteId=6587404&userId=6568565"
            target="_black"
            style="position: absolute;top:0;left:450px;text-decoration: none;"
          >业务咨询 ></a>
        </div>
      </div>
    </div>
    <div class="azupart2 minwd">
      <div class="wrap">
        <div class="title wow fadeInDown">多功能集成，助力企业发展</div>
        <div class="tabtitle">
          <ul class="clearfix">
            <li @click="tabs(1)">
              <i>
                <img src="../../assets/images/azure/icoms1.png" class="img" />
                <img src="../../assets/images/azure/icoms1a.png" class="imga" />
              </i>
              <em>网站</em>
              <ins></ins>
            </li>
            <li @click="tabs(2)">
              <i>
                <img src="../../assets/images/azure/icoms2.png" class="img" />
                <img src="../../assets/images/azure/icoms2a.png" class="imga" />
              </i>
              <em>虚拟机</em>
              <ins></ins>
            </li>
            <li @click="tabs(3)">
              <i>
                <img src="../../assets/images/azure/icoms3.png" class="img" />
                <img src="../../assets/images/azure/icoms3a.png" class="imga" />
              </i>
              <em>云服务</em>
              <ins></ins>
            </li>
            <li @click="tabs(4)">
              <i>
                <img src="../../assets/images/azure/icoms4.png" class="img" />
                <img src="../../assets/images/azure/icoms4a.png" class="imga" />
              </i>
              <em>移动应用</em>
              <ins></ins>
            </li>
            <li @click="tabs(5)">
              <i>
                <img src="../../assets/images/azure/icoms5.png" class="img" />
                <img src="../../assets/images/azure/icoms5a.png" class="imga" />
              </i>
              <em>大数据</em>
              <ins></ins>
            </li>
            <li @click="tabs(6)">
              <i>
                <img src="../../assets/images/azure/icoms6.png" class="img" />
                <img src="../../assets/images/azure/icoms6a.png" class="imga" />
              </i>
              <em>媒体</em>
              <ins></ins>
            </li>
          </ul>
        </div>
        <div class="tabcont tabsitem">
          <div class="zprt clearfix tabitem">
            <div class="pic fl">
              <img src="../../assets/images/azure/itabpic1.jpg" />
            </div>
            <div class="word fr">
              <b>网站</b>
              <p>Azure 云平台提供多项支持网站开发的服务，帮助客户快速实现各种云端应用场景。其中最常见的场景是 Web 网站托管。允许使用 ASP．NET、PHP 或 Node.js 构建，并使用 FTP、Git 或 TFS进行快速部署。支持 SQL Database、Caching、CDN及Storage等。</p>
            </div>
          </div>
          <div class="zprt clearfix tabitem">
            <div class="pic fl">
              <img src="../../assets/images/azure/itabpic2.jpg" />
            </div>
            <div class="word fr">
              <b>虚拟机</b>
              <p>Azure 虚拟机（Azure Virtual Machines）服务提供托管在 Azure 平台上的虚拟主机资源，用户可以按所需的计算资源来配备虚拟机，虚拟机服务订阅的管理者要负责虚拟机上所有软件的部署、维护和管理。Azure 虚拟机具有跨区域、冗余和可自动缩放的优势。</p>
            </div>
          </div>
          <div class="zprt clearfix tabitem">
            <div class="pic fl">
              <img src="../../assets/images/azure/itabpic3.jpg" />
            </div>
            <div class="word fr">
              <b>云服务</b>
              <p>云服务是Windows Azure 中的企业级云平台，使用平台即服务 (PaaS) 环境创建高度可用的且可无限缩放的应用程序和服务。支持多层方案、自动化部署和灵活缩放。支持Cloud Services、SQL Database、Caching、Business Analytics、Service Bus、Identity等。</p>
            </div>
          </div>
          <div class="zprt clearfix tabitem">
            <div class="pic fl">
              <img src="../../assets/images/azure/itabpic4.jpg" />
            </div>
            <div class="word fr">
              <b>移动应用</b>
              <p>移动应用是Windows Azure提供的移动应用程序的完整后端解决方案，加速连接的客户端应用程序开发。在几分钟内并入结构化存储、用户身份验证和推送通知。支持SQL Database、Mobile 服务。并可以快速生成Windows Phone、Android或者iOS应用程序项目。</p>
            </div>
          </div>
          <div class="zprt clearfix tabitem">
            <div class="pic fl">
              <img src="../../assets/images/azure/itabpic5.jpg" />
            </div>
            <div class="word fr">
              <b>大数据</b>
              <p>Windows Azure 提供海量数据处理能力，可以从数据中获取可执行的洞察力，利用完全兼容的企业 Hadoop 服务。PaaS 产品/服务提供了简单的管理，并与 Active Directory 和 System Center 集成。支持Hadoop、Business Analytics、Storage、SQL Database、及在线商店 Marketplace等。</p>
            </div>
          </div>
          <div class="zprt clearfix tabitem">
            <div class="pic fl">
              <img src="../../assets/images/azure/itabpic6.jpg" />
            </div>
            <div class="word fr">
              <b>媒体</b>
              <p>支持插入、编码、保护、流式处理，可以在云中创建、管理和分发媒体。为此 PaaS 产品/服务提供从编码到内容保护再到流式处理和分析支持的所有内容。支持CDN及Storage存储。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="azupart3 minwd">
      <div class="wrap">
        <div class="title wow fadeInDown">我们的Azure 云产品</div>
        <div class="contm clearfix">
          <div class="zprt wow fadeInUp" data-wow-delay="0.2s">
            <div class="cont">
              <i>
                <img src="../../assets/images/azure/icosm1.jpg" />
              </i>
              <span>
                <b>
                  虚拟机
                  <em></em>
                </b>
              </span>
              <p>低成本、高效、高兼容性、高扩展性，通过云技术完全掌控服务器，根据业务需求对虚拟机进行维护，轻松实现均衡负载。</p>
            </div>
          </div>
          <div class="zprt wow fadeInUp" data-wow-delay="0.4s">
            <div class="cont">
              <i>
                <img src="../../assets/images/azure/icosm2.jpg" />
              </i>
              <span>
                <b>
                  CDN
                  <em></em>
                </b>
              </span>
              <p>将网站、视频、应用快速安全地分发至用户最近的节点，让用户就近获取所需内容，高性能、低延时的内容分发服务。</p>
            </div>
          </div>
          <div class="zprt wow fadeInUp" data-wow-delay="0.6s">
            <div class="cont">
              <i>
                <img src="../../assets/images/azure/icosm3.jpg" />
              </i>
              <span>
                <b>
                  媒体服务
                  <em></em>
                </b>
              </span>
              <p>用安全的方式随时随地跨设备传送媒体内容、提供可伸缩的端到端媒体解决方案。</p>
            </div>
          </div>
          <div class="zprt wow fadeInUp" data-wow-delay="0.8s">
            <div class="cont">
              <i>
                <img src="../../assets/images/azure/icosm4.jpg" />
              </i>
              <span>
                <b>
                  云服务
                  <em></em>
                </b>
              </span>
              <p>仅需上传应用程序，从资源配置、负载平衡到运行监控均由Azure执行，即可部署可用度高、且可无限缩放的应用程序和 API。</p>
            </div>
          </div>
          <div class="zprt wow fadeInUp" data-wow-delay="0.2s">
            <div class="cont">
              <i>
                <img src="../../assets/images/azure/icosm5.jpg" />
              </i>
              <span>
                <b>
                  分析服务
                  <em></em>
                </b>
              </span>
              <p>强大高效的分析引擎，能够快速地部署、测试、交付和管理的 BI 解决方案。</p>
            </div>
          </div>
          <div class="zprt wow fadeInUp" data-wow-delay="0.4s">
            <div class="cont">
              <i>
                <img src="../../assets/images/azure/icosm6.jpg" />
              </i>
              <span>
                <b>
                  Power BI Embedded
                  <em></em>
                </b>
              </span>
              <p>低成本、轻松、高效的创建最新状态、具有吸引力的交互式报告，让应用中的数据更加生动。</p>
            </div>
          </div>
          <div class="zprt wow fadeInUp" data-wow-delay="0.6s">
            <div class="cont">
              <i>
                <img src="../../assets/images/azure/icosm7.jpg" />
              </i>
              <span>
                <b>
                  Azure Cosmos DB
                  <em></em>
                </b>
              </span>
              <p>低延迟、广分部、高可用性，并由高级别服务协议（SLA）支持的多模型的数据库服务。</p>
            </div>
          </div>
          <div class="zprt wow fadeInUp" data-wow-delay="0.8s">
            <div class="cont">
              <i>
                <img src="../../assets/images/azure/icosm8.jpg" />
              </i>
              <span>
                <b>
                  MySQL
                  <em></em>
                </b>
              </span>
              <p>兼容 MySQL，帮助用户快速部署，降低运维成本，提供高可靠、高安全、高可用、高性价比的开源数据库平台。</p>
            </div>
          </div>
        </div>
        <div
          class="btn tzform wow fadeInUp"
          style="position: relative;margin-bottom:100px;margin-top:50px"
        >
          <!-- href="azureProduct.php" -->
          <a style="position: absolute;top:0;left:100px;text-decoration: none;">显示所有产品 ></a>
          <a
            href="http://p.qiao.baidu.com/cps/chat?siteId=6587404&userId=6568565"
            target="_black"
            style="position: absolute;top:0;left:600px;text-decoration: none;"
          >立即咨询 ></a>
        </div>
        <div class="azucase minwd">
          <div class="wrap">
            <h1 class="wow fadeInDown">Azure业务遍布全球</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import logo from "../../assets/images/logo.png";
export default {
  data() {
    return {
      ConShow: false,
    };
  },
  metaInfo: {
    title: "夏龙通信官网--云计算",
    meta: [
      {
        name: "keywords",
        content:
          "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入--云计算",
      },
      {
        name: "description",
        content:
          "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--云计算",
      },
    ],
  },
  components: {
    // Header
  },
  props: [],
  methods: {
    tabs(id) {
      // console.log(id);
      document.querySelectorAll(".tabsitem .tabitem").forEach((el) => {
        el.style.display = "none";
        // console.log(el);
        document.querySelector(
          `.tabsitem .tabitem:nth-of-type(${id})`
        ).style.display = "block";
      });
    },
  },
  mounted() {
    this.ConShow = true;
    this.$eventbus.$emit("banner_id", "21");
    localStorage.setItem("banner_id", "21");
    document.querySelectorAll(".tabsitem .tabitem").forEach((el) => {
      el.style.display = "none";
      // console.log(el);
      let tabitem = document.querySelector(".tabsitem .tabitem:nth-of-type(1)");
      tabitem.style.display = "block";
    });
    document.querySelectorAll(".clearfix li ins").forEach((el) => {
      el.style.display = "none";
    });
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";
@import "../../assets/css/mystyle.css";
@import "../../assets/css/animate.min.css";
#azure {
  color: black;
  width: 75rem;
  min-height: 50rem;
  // background: blanchedalmond;
  display: block;
}
</style>