<template>
  <div
    id="header"
    :style="
      'height:' +
      ($route.meta.banner ? '60rem' : '30rem') +
      ';background-image:url(' +
      image +
      ')'
    "
  >
    <header>
      <div id="logo" @click="linkTo('/')">
        <img :src="logo" class="logo" alt="logo" />
        <p>夏龍通信</p>
      </div>
      <Nav />
      <div id="internationalization">
        <p>EN / 中 / 繁</p>
      </div>
    </header>

    <div id="banner">
      <el-carousel
        indicator-position="none"
        height="60rem"
        v-if="$route.meta.banner"
      >
        <el-carousel-item v-for="item in imglist" :key="item.id">
          <a :href="item.url"><img :src="item.image" :alt="item.title" /></a>
        </el-carousel-item>
      </el-carousel>

      <section v-else v-show="banner_Show">
        <aside class="animated bounceInLeft">
          <div class="grid__item color-7">
            <a class="link link--mallki" href="#">
              {{ title }}
              <span :data-letters="title"></span>
              <span :data-letters="title"></span>
            </a>
          </div>
          <p>{{ content }}</p>
          <div class="elbtn1" v-if="$route.meta.solution_banner">
            <a
              class="btn btn--sm btn--white"
              v-if="allow === 1"
              @click="router(sid, '申请')"
              >申请体验</a
            >
            <a
              class="btn btn--sm btn--white"
              v-else
              @click="router(sid, '咨询')"
              >咨询服务</a
            >
          </div>
          <a
            class="btn btn--sm btn--white elbtn2"
            v-show="btnshow"
            @click="BtnShow"
            >{{ btntitle }}</a
          >
        </aside>
        <img class="animated bounceInRight" :src="small_image"  />
      </section>
    </div>

    <el-dialog
      title="视频播放"
      :modal="false"
      :visible.sync="centerDialogVisible"
      width="75rem"
    >
      <video
        id="video"
        :src="btnvideo"
        autoplay="autoplay"
        controls="controls"
        width="1200"
        height="600"
        style="margin-left: -20px"
      ></video>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ShowDialog">取 消</el-button>
        <!-- <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import logo from "../../assets/images/logo.png";
import bannerbg from "../../assets/images/banner_left.jpg";
import Nav from "../nav/index.vue";
import { host } from "../../const";

export default {
  data() {
    return {
      centerDialogVisible: false,
      logo: logo,
      bannerbg: bannerbg,
      imglist: [],
      title: "",
      content: "",
      btnshow: false,
      allow: 0,
      sid: 0,
      image: "",
      small_image: "",
      banner_Show: false,
      btntitle: "默认按钮",
      btnvideo: "",
      btnurl: "",
    };
  },

  components: {
    Nav,
  },
  methods: {
    linkTo(url) {
      //点击跳转
      this.$router.push(url);
    },
    router(id, name) {
      console.log(id, name);
      localStorage.setItem("applyName", name);
      this.$router.push({
        name: "Apply",
        params: {
          id: id,
          name: name,
        },
      });
    },
    ShowDialog() {
      this.centerDialogVisible = false;
      document.querySelector("#video").pause();
    },
    BtnShow() {
      if (this.btnvideo != "") {
        this.centerDialogVisible = true;
        document.querySelector("#video").play();
      } else {
        window.open(this.btnurl);
      }
    },
    getbannerlist(id) {
      console.log(id);
      let url = host + "/portal/home/getbannerlist?type=" + id;
      this.$axios.get(url).then((res) => {
        if (res.data.data.length > 1) {
          // console.log("index_banner 内容", res.data.data);
          this.imglist = res.data.data;
        } else {
          // console.log(
          //   "其他_banner 内容",
          //   res.data.data[0].title,
          //   res.data.data
          // );
          this.banner_Show = true;
          this.title = res.data.data[0].title;
          this.content = res.data.data[0].content;
          this.image = res.data.data[0].image;
          this.small_image = res.data.data[0].small_image;
          if (res.data.data[0].url != "") {
            this.btnshow = true;
            this.btntitle = "点击跳转";
            this.btnurl = res.data.data[0].url;
          } else if (res.data.data[0].video != "") {
            this.btnshow = true;
            this.btntitle = "点击查看";
            this.btnvideo = res.data.data[0].video;
          } else {
            this.btnshow = false;
          }
        }
      });
    },
  },
  mounted() {
    this.$eventbus.$on("banner_id", (id) => {
      this.banner_Show = false;
      this.getbannerlist(id);
    });
    let bannerId = localStorage.getItem("banner_id");
    this.banner_Show = false;
    this.getbannerlist(bannerId);

    this.$eventbus.$on("banner_con", (con) => {
      // console.log("banner内容：", con);
      // console.log(typeof con.allow);
      this.allow = con.allow;
      this.title = con.title;
      this.content = con.content;
      this.sid = con.sid;
      this.image = con.image;
      this.small_image = con.small_image;
      // this.banner_Show = false;
      this.btnshow = false;
      setTimeout(() => {
        this.banner_Show = true;
      }, 100);
    });
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";
@import "index.css";
#header {
  width: 100%;
  height: 30rem;
  background: #999;
  // background-image: url(../../assets/images/header_bg.jpg);
  position: relative;
  color: white;
  .column;
  justify-content: center;
  z-index: 9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5), 0 0 6px rgba(0, 0, 0, 0.5);
  header {
    // background: #99a9bf;
    width: 75rem;
    position: absolute;
    left: 50%;
    margin-left: -37.5rem;
    // width: 100%;
    height: 4.0625rem;
    // margin-top: 5rem;
    .row;
    justify-content: center;
    #logo {
      width: 15%;
      height: 100%;
      // background: bisque;
      .row;
      justify-content: center;
      img {
        width: 15%;
      }
      p {
        font-size: 1.25rem;
        color: white;
        font-weight: 400;
        margin-left: 10%;
      }
    }
    #internationalization {
      width: 10%;
      height: 100%;
      // background: bisque;
      .row;
      justify-content: center;
    }
  }
  #banner {
    z-index: -9;
    width: 100%;
    height: 60rem;
    position: relative;
    transition: ease-in-out all 3s;
    .el-carousel {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .el-carousel-item {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
        }
      }
      .el-carousel__item h3 {
        color: #475669;
        font-size: 18px;
        opacity: 0.75;
        line-height: 300px;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
      }

      .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
      }
      img {
        // background-size: contain;
        position: absolute;
        left: 50%;
        margin-left: -960px;
        transition: ease all 3s;
      }
      img:hover {
        transform: scale(1.05, 1.05);
      }
    }
    section {
      position: absolute;
      top: 40%;
      left: 50%;
      width: 75rem;
      height: 50%;
      // background: wheat;
      // margin: 0 auto;
      margin-left: -37rem;
      .row;
      justify-content: space-around;
      aside {
        position: relative;
        width: 50%;
        height: 100%;
        .column;
        justify-content: space-between;
        align-items: flex-start;
        // p:nth-of-type(1) {
        //   font-size: 1.5rem;
        //   font-weight: bolder;
        // }
        position: relative;
        h1 {
          position: absolute;
          top: 0;
          left: 50%;
          font-family: "Montserrat", Arial, sans-serif;
          font-size: calc(10px + 2.5vw);
          font-weight: 700;
          color: #fff;
          letter-spacing: 0.02em;
          text-transform: uppercase;
          // text-shadow: 0 0 0.15em #1da9cc;
          text-shadow: 0 0 0.15em rgba(255, 255, 255, 0.8);
          user-select: none;
          white-space: nowrap;
          filter: blur(0.007em);
          animation: shake 2.5s linear forwards;
        }

        h1 span {
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(-50%, -50%);
          -webkit-clip-path: polygon(10% 0%, 44% 0%, 70% 100%, 55% 100%);
          clip-path: polygon(10% 0%, 44% 0%, 70% 100%, 55% 100%);
        }

        h1::before,
        h1::after {
          content: attr(data-text);
          position: absolute;
          top: 0;
          left: 0;
        }

        h1::before {
          animation: crack1 2.5s linear forwards;
          -webkit-clip-path: polygon(0% 0%, 10% 0%, 55% 100%, 0% 100%);
          clip-path: polygon(0% 0%, 10% 0%, 55% 100%, 0% 100%);
        }

        h1::after {
          animation: crack2 2.5s linear forwards;
          -webkit-clip-path: polygon(44% 0%, 100% 0%, 100% 100%, 70% 100%);
          clip-path: polygon(44% 0%, 100% 0%, 100% 100%, 70% 100%);
        }

        @keyframes shake {
          5%,
          15%,
          25%,
          35%,
          55%,
          65%,
          75%,
          95% {
            filter: blur(0.018em);
            transform: translateY(0.018em) rotate(0deg);
          }

          10%,
          30%,
          40%,
          50%,
          70%,
          80%,
          90% {
            filter: blur(0.01em);
            transform: translateY(-0.018em) rotate(0deg);
          }

          20%,
          60% {
            filter: blur(0.03em);
            transform: translate(-0.018em, 0.018em) rotate(0deg);
          }

          45%,
          85% {
            filter: blur(0.03em);
            transform: translate(0.018em, -0.018em) rotate(0deg);
          }

          100% {
            filter: blur(0.007em);
            transform: translate(0) rotate(-0.5deg);
          }
        }

        @keyframes crack1 {
          0%,
          95% {
            transform: translate(-50%, -50%);
          }

          100% {
            transform: translate(-51%, -48%);
          }
        }

        @keyframes crack2 {
          0%,
          95% {
            transform: translate(-50%, -50%);
          }

          100% {
            transform: translate(-49%, -53%);
          }
        }

        p {
          position: absolute;
          top: 50%;
          left: 0;
        }
        .elbtn1,
        .elbtn2 {
          position: absolute;
          top: 98%;
          left: 0;
        }
      }
      img {
        width: 25%;
      }
    }
  }
}
</style>
