<template>
  <div id="cid_7" class="animated fadeInUp">
    <!-- 六大优势 -->
    <div class="content">
      <p class="title">{{modular.content.title}}</p>
      <section>
        <aside>
          <img :src="modular.content.list[0].image1" alt />
        </aside>
        <aside>
          <div>
            <strong>{{modular.content.list[0].title1}}</strong>
            <p v-html="modular.content.list[0].remark1"></p>
          </div>
        </aside>
      </section>
      <section>
        <aside>
          <img :src="modular.content.list[0].image2" alt />
        </aside>
        <aside>
          <div>
            <strong>{{modular.content.list[0].title2}}</strong>
            <p v-html="modular.content.list[0].remark2"></p>
          </div>
        </aside>
      </section>
      <section>
        <aside>
          <img :src="modular.content.list[0].image3" alt />
        </aside>
        <aside>
          <div>
            <strong>{{modular.content.list[0].title3}}</strong>
            <p v-html="modular.content.list[0].remark3"></p>
          </div>
        </aside>
      </section>
      <section>
        <aside>
          <img :src="modular.content.list[0].image4" alt />
        </aside>
        <aside>
          <div>
            <strong>{{modular.content.list[0].title4}}</strong>
            <p v-html="modular.content.list[0].remark4"></p>
          </div>
        </aside>
      </section>
      <section>
        <aside>
          <img :src="modular.content.list[0].image5" alt />
        </aside>
        <aside>
          <div>
            <strong>{{modular.content.list[0].title5}}</strong>
            <p v-html="modular.content.list[0].remark5"></p>
          </div>
        </aside>
      </section>
      <section>
        <aside>
          <img :src="modular.content.list[0].image6" alt />
        </aside>
        <aside>
          <div>
            <strong>{{modular.content.list[0].title6}}</strong>
            <p v-html="modular.content.list[0].remark6"></p>
          </div>
        </aside>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: ["modular"],
  methods: {},
  mounted() {},
};
</script>
<style scoped lang="less">
@import "../../../../assets/css/main.less";
#cid_7 {
  width: 100%;
  min-height: 50rem;
  // background: #f4f4f4;
  .content {
    width: 75rem;
    height: 100%;
    margin: 0 auto;
    .column;
    justify-content: space-around;
    align-items: center;
    .title {
      font-size: 1.5rem;
      font-weight: bolder;
    }
    section {
      transition: all ease 500ms;
      width: 100%;
      min-height: 25rem;
      // background: bisque;
      margin: 0 0 15px 0;
      padding: 15px 0;
      .row;
      justify-content: space-between;
      border-bottom: #999 solid 0.0625rem;
      aside:nth-of-type(1) {
        width: 50%;
        min-height: 20rem;
        // background: chocolate;
        .row;
        justify-content: center;
        padding: 0 10px;
        img {
          width: 100%;
          // height: 100%;
        }
      }
      aside:nth-of-type(2) {
        width: 40%;
        min-height: 20rem;
        // background: cornflowerblue;
        .row;
        justify-content: center;
        div {
          width: 90%;
          height: 90%;
          // background: coral;
        }
      }
    }
    section:hover {
      background: rgba(0, 0, 0, 0.1);
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    section:nth-of-type(2),
    section:nth-of-type(4),
    section:nth-of-type(6) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }
    section:nth-last-of-type(1) {
      border-bottom: none;
    }
  }
}
</style>