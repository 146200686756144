<template>
  <div id="nav">
    <div class="nav">
      <ul id="menu_nav">
        <li
          class="item_nav"
          v-for="item in headMenu"
          :key="item.id"
          @click="router(item)"
          @mouseover="nav_enter(item)"
        >
          <span>{{item.name}}</span>
        </li>
      </ul>
    </div>
    <transition name="slide-fade">
      <div id="nav_content" style="width:100% " v-show="show" @mouseleave="navleave()">
        <div class="nav_content">
          <component :is="type" :NavMenu="NavMenu"></component>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { host } from "../../const";
export default {
  data() {
    return {
      activeIndex: "1",
      headMenu: [],
      NavMenu: [],
      clickflag: false,
      activeName: "second",
      show: false,
      type: function (resolve) {
        require(["./components/cloud.vue"], resolve);
      },
    };
  },
  methods: {
    router(item) {
      let id = item.id;
      // console.log(item);
      document.querySelectorAll("#menu_nav li").forEach((el) => {
        el.style.borderBottom = "0";
      });
      if (id == 1) {
        this.show = false;
        this.$router.push("/");
        document.querySelector(
          "#menu_nav li:nth-of-type(1)"
        ).style.borderBottom = "1px solid white";
      } else if (id == 2) {
        this.show = true;
        this.type = function (resolve) {
          require(["./components/cloud.vue"], resolve);
        };
        this.NavMenu = this.headMenu[1].child;
      } else if (id == 4) {
        this.show = true;
        this.type = function (resolve) {
          require(["./components/bigdata.vue"], resolve);
        };
        this.NavMenu = this.headMenu[2].child;
      } else if (id == 5) {
        this.show = true;
        this.type = function (resolve) {
          require(["./components/solution.vue"], resolve);
        };
        this.NavMenu = this.headMenu[3].child;
      } else if (id == 6) {
        // console.log(item);
        this.show = false;
        // this.type = function (resolve) {
        //   require(["./components/fiveG.vue"], resolve);
        // };
        // this.NavMenu = this.headMenu[4].child;
        localStorage.setItem("page_id", item.sid);
        this.$router.push({ path: "/solution", query: { ID: item.sid } });
        this.$eventbus.$emit("page_id", item.sid);
        document.querySelector(
          "#menu_nav li:nth-of-type(5)"
        ).style.borderBottom = "1px solid white";
      } else if (id == 7) {
        this.show = true;
        this.type = function (resolve) {
          require(["./components/consultation.vue"], resolve);
        };
      } else if (id == 8) {
        this.show = true;
        this.type = function (resolve) {
          require(["./components/about.vue"], resolve);
        };
      }
    },
    nav_enter(item) {
      let id = item.id;
      // console.log(item.id);
      if (id == 1) {
        this.show = false;
      } else if (id == 2) {
        this.show = true;
        this.type = function (resolve) {
          require(["./components/cloud.vue"], resolve);
        };
        this.NavMenu = this.headMenu[1].child;
      } else if (id == 4) {
        this.show = true;
        this.type = function (resolve) {
          require(["./components/bigdata.vue"], resolve);
        };
        this.NavMenu = this.headMenu[2].child;
      } else if (id == 5) {
        this.show = true;
        this.type = function (resolve) {
          require(["./components/solution.vue"], resolve);
        };
        this.NavMenu = this.headMenu[3].child;
      } else if (id == 6) {
        this.show = false;
        // this.type = function (resolve) {
        //   require(["./components/fiveG.vue"], resolve);
        // };
        // this.NavMenu = this.headMenu[4].child;
      } else if (id == 7) {
        this.show = true;
        this.type = function (resolve) {
          require(["./components/consultation.vue"], resolve);
        };
      } else if (id == 8) {
        this.show = true;
        this.type = function (resolve) {
          require(["./components/about.vue"], resolve);
        };
      }
    },
    navleave() {
      this.show = false;
    },
  },
  created() {
    this.$nextTick(() => {
      // 禁用右键
      // document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      // document.onselectstart = new Function("event.returnValue=false");
    });
  },
  mounted() {
    this.$eventbus.$on("nav_show", (flag) => {
      this.show = flag;
    });

    let url = host + "/portal/home/gethomemenu";
    this.$axios.get(url).then((res) => {
      // console.log(res.data.data.headMenu);
      this.headMenu = res.data.data.headMenu;
      this.$eventbus.$emit("footMenu", res.data.data.footMenu);
    });
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";
@import "../../assets/css/nav_marker.less";

#menu_nav {
  margin-top: 25px;
  padding: 0;
  width: 55rem;
  height: 4rem;
  // background: oldlace;
  list-style: none;
  font-weight: 400;
  .row;
  justify-content: space-around;
  // border-bottom: 2px solid white;
  li {
    width: 6.25rem;
    height: 3.75rem;
    background-color: rgba(255, 255, 255, 0);
    .row;
    justify-content: center;
    margin-bottom: 10px;
    // border-bottom: 2px solid rgba(255,255,255,0);
    transition: 2s all ease;
    position: relative;
    span {
      font-size: 1rem;
    }
  }
  li:hover {
    color: black;
    background: rgba(255, 255, 255, 1);
  }
  li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: 0;
    height: 100%;
    border-bottom: 2px solid rgba(255, 255, 255, 1);
    transition: 0.2s all ease;
  }
  li:hover::before {
    width: 100%;
    left: 0;
  }
  li:hover ~ li::before {
    left: 0;
  }
}

#nav_content {
  width: 100%;
  height: 30rem;
  margin: 0 auto;
  // opacity:1;
  background-image: linear-gradient(
    rgba(6, 19, 106, 0.8) 5%,
    rgba(3, 10, 57, 0.8) 90%,
    rgba(6, 19, 106, 1)
  );
  position: absolute;
  // top: 170px;
  top: 5.0125rem;
  left: 0;
  z-index: 999;
  .nav_content {
    width: 75rem;
    height: 30rem;
    // background: white;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -37.5rem;
  }
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>