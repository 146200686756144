<template>
  <div id="product_content">
    <div class="product_content">
      <el-card
        body-style="padding:0"
        class="box-card"
        shadow="hover"
        v-for="item in contentList"
        :key="item.id"
      >
        <div class="content">
          <p>
            {{ item.name }}
            <span v-if="item.flag === '1'">热销</span>
          </p>
          <p>{{ item.remark }}</p>
          <button @click="jump(item)">产品详情</button>
          <p class="timer">{{ item.price }}</p>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
// import logo from "../../assets/images/logo.png";
import { host } from "../../../../const";
export default {
  data() {
    return {
      contentList: [],
    };
  },
  methods: {
    jump(item) {
      console.log(item);
      // window.open(item.url);
      let local = "https://cloud.cdt.cc/";
      if (item.sid != 0) {
        this.$router.push({ path: "/solution", query: { ID: item.sid } });
        this.$eventbus.$emit("page_id", item.sid);
        localStorage.setItem("page_id", item.sid);
        document.querySelectorAll("#menu_nav li").forEach((el) => {
          el.style.borderBottom = "0";
        });
        document.querySelector(
          "#menu_nav li:nth-of-type(4)"
        ).style.borderBottom = "1px solid white";
      } else if (item.bid != 0) {
        this.$router.push({
          path: "/bigdata/Content",
          query: { ID: item.bid },
        });
        localStorage.setItem("bid", item.bid);
        this.$eventbus.$emit("bid", item.bid);
        this.$eventbus.$emit("title", "Content");
        document.querySelectorAll("#menu_nav li").forEach((el) => {
          el.style.borderBottom = "0";
        });
        document.querySelector(
          "#menu_nav li:nth-of-type(3)"
        ).style.borderBottom = "1px solid white";
      } else {
        if (item.url.substring(0, 21) == local) {
          window.open(item.url);
        } else {
          this.$router.push(item.url);
        }
      }
    },
  },
  mounted() {
    let url = host + "/portal/home/getallproduct";
    this.$axios.get(url).then((res) => {
      this.contentList = res.data.data.product_recommend;
    });
  },
};
</script>
<style scoped lang="less">
@import "../../../../assets/css/main.less";
.product_content {
  width: 56.25rem;
  height: 37.5rem;
  //   background: chocolate;
  .row;
  justify-content: flex-start;
  align-content: space-between;
  flex-wrap: wrap;
  .box-card {
    width: 17.3rem;
    height: 12.25rem;
    border-radius: 0;
    // background: white;
    color: black;
    padding: 0 0.625rem;
    margin-right: 1px;
    .content {
      // background: olive;
      width: 80%;
      height: 17.3rem;
      margin: 0 auto;
      position: relative;
      p {
        margin: 0;
        text-align: justify;
      }
      p:nth-of-type(1) {
        font-size: 1rem;
        font-weight: 600;
        margin: 20% 0 5%;
        span {
          background: #d84f62;
          font-size: 0.875rem;
          font-weight: normal;
          color: white;
          padding: 0 0.1875rem;
        }
      }
      p:nth-of-type(3) {
        font-size: 0.875rem;
      }
      button {
        position: absolute;
        top: 40%;
        display: none;
        border: none;
        border-radius: 0.125rem;
        background: #d84f62;
        color: white;
        padding: 0.3125rem 1.5rem;
      }
    }
  }
  .box-card:nth-of-type(3),
  .box-card:nth-of-type(6),
  .box-card:nth-of-type(9) {
    margin-right: 0;
  }
  .timer {
    position: absolute;
    top: 40%;
  }
  .box-card:hover {
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 1), 5px 0 12px 0 rgba(0, 0, 0, 1);
  }
  .box-card:hover button {
    display: block;
  }
  .box-card:hover .timer {
    display: none;
  }
}
</style>