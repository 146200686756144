<template>
  <div id="join" v-show="ConShow" class="animated fadeInUp">
    <div class="join">
      <article>
        <section>
          <p class="title">
            <!-- <i class="el-icon-more"></i> -->
            公司介绍
          </p>
          <aside>
            <p>
              广东夏龙通信有限公司（以下简称“夏龙通信”）成立于2007年7月，中国总部设于广东深圳，在广州、
              东莞、青岛设有运营分支机构，主要经营数据通信、云计算和互联网增值业务。
            </p>
            <p>夏龙通信作为中国云计算资源整合服务供应商的佼佼者，致力于建设高保障数据中心。夏龙通信秉持中立路线，与所有运营商保持互联互通，承载高性能云计算服务平台，通过亚太地区高速互联宽带网，为客户专业提供数据中心托管（IDC）、云计算(Cloud)、互联网接入(ISP)、全球组网(MPLS VPN)、内容分发(CDN)、系统集成(SI)等服务。以卓越可靠的信息安全服务，优质稳定的数据传输，超大容量的信息处理能力，为诸多企业带来高效网络资源服务。</p>
          </aside>
        </section>
        <aside name="img_jpg">
          <img :src="casejpg" alt="夏龙" />
        </aside>
      </article>
      <article>
        <section>
          <p class="title">
            <i class="el-icon-more"></i>加入夏龙
          </p>
          <aside>
            <span v-for="item in welfare" :key="item.id">{{item}}</span>
          </aside>
        </section>
        <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
          <el-tab-pane
            v-for="item in join"
            :key="item.id"
            :label="item.name"
            :name="item.id.toString()"
            class="tab_item"
          >
            <aside v-html="item.post"></aside>
            <aside v-html="item.server"></aside>
          </el-tab-pane>
        </el-tabs>
      </article>
    </div>
  </div>
</template>
<script>
import casejpg from "../../assets/images/Bg_index.jpg";
import { host } from "../../const";
export default {
  data() {
    return {
      ConShow: false,
      casejpg: casejpg,
      activeName: "1",
      welfare: [
        "福利待遇",
        "联 系 人：刘小姐",
        "联系邮箱：cdt-hr@cdt.cc",
        "五险一金 带薪年假 周末双休 定期体检 员工旅游",
        "联系电话：0755-33319121",
        "联系地址：深圳市福田区梅坳八路268号深燃大厦B座9楼911室",
      ],
      join: [],
    };
  },
  metaInfo: {
    title: "夏龙通信官网--加入我们",
    meta: [
      {
        name: "keywords",
        content:
          "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入--加入我们",
      },
      {
        name: "description",
        content:
          "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--加入我们",
      },
    ],
  },
  components: {},
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getjoin() {
      let url = host + "/portal/home/getjoin";
      this.$axios.get(url).then((res) => {
        // console.log("加入夏龙", res.data.data);
        this.join = res.data.data;
      });
    },
  },
  mounted() {
    this.$eventbus.$emit("banner_id", 19);
    localStorage.setItem("banner_id", 19);
    this.ConShow = true;
    this.getjoin();
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";
#join {
  width: 100%;
  min-height: 20rem;
  //   background: blanchedalmond;
  color: black;
  .join {
    width: 75rem;
    height: 100%;
    // background: blanchedalmond;
    margin: 5% auto;
    article:nth-of-type(1) {
      width: 75rem;
      height: 31.25rem;
      // background: chocolate;
      .row;
      justify-content: space-between;
      section {
        width: 48%;
        height: 80%;
        // background: cornflowerblue;
        .column;
        justify-content: space-around;
        align-items: flex-start;
        .title;
        aside {
          width: 100%;
          height: 40rem;
          // background: cadetblue;
          .column;
          justify-content: flex-start;
          p {
            width: 36rem;
            text-indent: 2em;
            // background: chartreuse;
            text-align: justify;
            line-height: 200%;
            color: black;
          }
          p:nth-of-type(2) {
            margin-top: -10px;
          }
        }
      }
      aside[name="img_jpg"] {
        width: 50%;
        height: 100%;
        // background: brown;
        .row;
        justify-content: center;
        img {
          width: 100%;
        }
      }
    }
    article:nth-of-type(2) {
      margin-top: 3rem;
      width: 100%;
      min-height: 40rem;
      transition: ease-in-out all 1s;
      // background: beige;
      .column;
      justify-content: flex-start;
      section {
        transition: ease-in-out all 1s;
        width: 100%;
        height: 30%;
        margin-bottom: 20px;
        .title;
        aside {
          transition: ease-in-out all 1s;
          width: 100%;
          height: 3rem;
          // background: crimson;
          .row;
          flex-wrap: wrap;
          justify-content: space-between;
          span {
            font-size: 0.75rem;
            width: 30%;
            height: 50%;
            // background: darkcyan;
          }
        }
      }
      .el-tabs {
        transition: ease-in-out all 1s;
        width: 100%;
        min-height: 28rem;
        // background: cornflowerblue;
        .tab_item {
          width: 100%;
          height: 80%;
          // background: chocolate;
          .row;
          justify-content: space-between;
          align-items: flex-start;
          aside {
            width: 45%;
            height: 100%;
            // background: beige;
            text-align: justify;
          }
        }
      }
    }
  }
}
</style> 