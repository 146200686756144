<template>
  <div id="modular" v-show="modular.card_Show">
    <Item1 :modular="modular" v-if="modular.content.cid===1" />
    <Item2 :modular="modular" v-if="modular.content.cid===2" />
    <Item3 :modular="modular" v-if="modular.content.cid===3" />
    <Item4 :modular="modular" v-if="modular.content.cid===6" />
    <Item5 :modular="modular" v-if="modular.content.cid===8" />
    <Item6 :modular="modular" v-if="modular.content.cid===9" />
    <Item7 :modular="modular" v-if="modular.content.cid===10" />
    <div id="images">
      <article>
        <img :src="images" @click="narrow" alt />
      </article>
    </div>
  </div>
</template>

<script>
import product from "../../../assets/images/product.jpg";
import Item1 from "../components/cards/item1.vue";
import Item2 from "../components/cards/item2.vue";
import Item3 from "../components/cards/item3.vue";
import Item4 from "../components/cards/item4.vue";
import Item5 from "../components/cards/item5.vue";
import Item6 from "../components/cards/item6.vue";
import Item7 from "../components/cards/item7.vue";
export default {
  data() {
    return {
      images: product,
    };
  },
  components: {
    Item1,
    Item2,
    Item3,
    Item4,
    Item5,
    Item6,
    Item7,
  },
  props: ["modular"],
  methods: {
    enlarge(img) {
      // console.log(img);
      this.images = img;
      document.querySelector("#images").style.display = "block";
    },
    narrow() {
      document.querySelector("#images").style.display = "none";
    },
  },
  mounted() {
    // console.log(this.modular);
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/main.less";
#modular {
  width: 100%;
  height: 100%;
  // background: beige;
  .column;
  justify-content: space-around;
  #images {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    article {
      width: 50%;
      height: 50%;
      // background: floralwhite;
      // margin: 12.5% 0 0 25%;
      position: fixed;
      top: 15%;
      left: 25%;
      img {
        width: 100%;
        // .narrow;
      }
    }
  }
}
</style>