<template>
  <div id="consult">
    <article>
      <strong id="title">技术支持</strong>
      <section>
        <p class="icon_p">
          <i class="fa fa-address-card-o fa-5x"></i>
        </p>
        <aside>
          <p>7*24小时标准化客户服务体系</p>
          <p
            style="line-height: 250%;width:46rem;text-align: justify;"
          >夏龙通信客户服务体系的宗旨是“客户永远是第一位”，从客户的实际需求出发，为客户提供真正有价值的服务，帮助客户更好地使用产品。以“良好的客服形象、良好的技术、良好的客户关系、良好的品牌”的核心服务理念，及时和全方位地关注客户的每一个服务需求，并通过提供广泛、全面和快捷的服务，使客户体验到无处不在的满意和可信赖的贴心感受。</p>
        </aside>
      </section>
    </article>
    <article>
      <p class="title">
        <i class="el-icon-more"></i>联系方式
      </p>
      <section>
        <aside v-for="item in contact" :key="item.id">
          <p class="num">0{{item.id}}</p>
          <p class="title">{{item.title}}</p>
          <div>
            <p v-for="el in item.list" :key="el.id">{{el}}</p>
          </div>
        </aside>
      </section>
    </article>
    <article>
      <section>
        <p class="title">
          <i class="el-icon-more"></i>服务支持
        </p>
        <aside>
          <div id="support" v-for="item in support" :key="item.id" @click="support_item(item)">
            <div class="content">
              <!-- <span>STEP0{{item.id}}</span> -->
              <i class="fa fa-user fa-3x"></i>
              <strong>{{item.name}}</strong>
            </div>
          </div>
        </aside>
      </section>
    </article>
    <article>
      <!-- <p class="title">
        <i class="el-icon-more"></i>列表目录
      </p>-->
      <section v-show="news_Show" class="animated fadeInUp">
        <aside>
          <ul>
            <li
              style="width:300px;list-style:square inside;line-height: 250%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
              v-for="item in itemlist.item"
              :key="item.id"
              @click="bindtag(item)"
            >{{item.title}}</li>
          </ul>
        </aside>
        <aside v-html="con.content"></aside>
      </section>
    </article>
  </div>
</template>

<script>
import { host } from "../../const";

export default {
  inject: ["reload"],
  data() {
    return {
      contact: [
        {
          id: 1,
          title: "电话",
          list: ["0755-88824600"],
        },
        {
          id: 2,
          title: "邮箱",
          list: ["CS@cdt.cc"],
        },
        {
          id: 3,
          title: "网页客服",
          list: ["右边客服qq“备案/报障”咨询"],
        },
        {
          id: 4,
          title: "机房地址",
          list: ["深圳梅林机房：深圳市福田区上梅林中康路51号向福大厦1、2楼"],
        },
      ],
      support: [],
      itemlist: [],
      con: {},
      news_Show: false,
    };
  },
  metaInfo: {
    title: "夏龙通信官网--咨询服务",
    meta: [
      {
        name: "keywords",
        content:
          "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入--咨询服务",
      },
      {
        name: "description",
        content:
          "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--咨询服务",
      },
    ],
  },
  components: {
    // Bar,
    // Content
  },
  methods: {
    jump() {
      this.$router.push("/about/Contact");
    },
    bindtag(item) {
      // console.log(item.title);
      this.con = item;
      this.news_Show = true;
      let item_Article = document.querySelectorAll(
        "article:nth-of-type(4) section aside:nth-of-type(1) ul li"
      );
      item_Article.forEach((ele) => {
        // console.log(ele);
        if (ele.innerHTML == item.title) {
          ele.style.borderBottom = "2px dashed rgba(1, 35, 124, 1)";
        } else {
          ele.style.borderBottom = "none";
        }
      });
    },
    support_item(item) {
      localStorage.setItem("consulID", item.id);
      this.$eventbus.$emit("consulID", item.id);
      // console.log(item);
      this.getconsultationitemlist(item.id, 1);
      this.ItemChange(item.id);
      this.reload();
    },
    getconsultationitemlist(id, page) {
      let url =
        host +
        "/portal/home/getconsultationitemlist?id=" +
        id +
        "&page=" +
        page;
      this.$axios.get(url).then((res) => {
        console.log("列表目录", res.data.data);
        this.itemlist = res.data.data;
        this.con = res.data.data.item[0];
        this.news_Show = true;
        setTimeout(() => {
          document.querySelector(
            "article:nth-of-type(4) section aside:nth-of-type(1) ul li:nth-of-type(1)"
          ).style.borderBottom = "2px dashed rgba(1, 35, 124, 1)";
        }, 200);
      });
    },
    getconsultationlist() {
      let url = host + "/portal/home/getconsultationlist";
      this.$axios.get(url).then((res) => {
        console.log("服务支持", res.data.data);
        this.support = res.data.data;
      });
    },
    ItemChange(id) {
      setTimeout(() => {
        let support_item = document.querySelectorAll("#support");
        support_item.forEach((el) => {
          let num = el.children[0].children[0].innerHTML;
          if (num.charAt(num.length - 1) == id) {
            el.style.borderRadius = "10%";
            el.style.boxShadow =
              "0 2px 1px rgba(0,0,0,0.09),0 4px 2px rgba(0,0,0,0.09),0 8px 4px rgba(0,0,0,0.09),0 16px 8px rgba(0,0,0,0.09),0 32px 16px rgba(0,0,0,0.09)";
          } else {
            el.style.borderRadius = "none";
          }
        });
      }, 500);
    },
  },
  mounted() {
    this.$eventbus.$emit("banner_id", 13);
    localStorage.setItem("banner_id", 13);
    this.getconsultationlist();
    // this.getconsultationitemlist(1, 1);
    let ID = localStorage.getItem("consulID");
    this.getconsultationitemlist(ID, 1);
    this.ItemChange(ID);
    // this.$eventbus.$on("consulID", (id) => {
    //   this.getconsultationitemlist(id, 1);
    //   this.ItemChange(id);
    // });
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";
#consult {
  width: 100%;
  min-height: 100vh;

  // background: rgba(0,0,0, 0.8);
  // background: blanchedalmond;
  margin: 0 auto;
  .column;
  justify-content: center;
  padding: 2% 0;
  article:nth-of-type(1) {
    width: 75rem;
    height: 31.25rem;
    // background: blanchedalmond;
    color: black;
    .column;
    justify-content: space-around;
    border-bottom: 1px solid #999;
    #title {
      font-size: 1.5rem;
    }
    section {
      width: 100%;
      height: 80%;
      // background: cadetblue;
      .row;
      justify-content: space-around;
      .icon_p {
        width: 13%;
        height: 38%;
        background: white;
        border: 0.0625rem solid #999;
        border-radius: 50%;
        border-right: none;
        border-bottom: none;
        .row;
        justify-content: center;
        i {
          color: @bg;
        }
      }
      .icon_p:hover {
        box-shadow: 3px 8px 8px #888888;
      }
      aside {
        width: 60%;
        height: 100%;
        // background: chocolate;
        .column;
        justify-content: space-around;
        align-items: flex-start;
        p:nth-of-type(1) {
          font-size: 1.5rem;
        }
        .el-button {
          background: #ee2d50;
          color: white;
        }
      }
    }
  }
  article:nth-of-type(2) {
    width: 75rem;
    height: 31.25rem;
    // background: blanchedalmond;
    color: black;
    .column;
    justify-content: space-around;
    .title;
    section {
      width: 100%;
      height: 60%;
      // background: cadetblue;
      .row;
      justify-content: space-around;
      aside {
        width: 20%;
        height: 100%;
        // background: bisque;
        .column;
        justify-content: space-around;
        align-items: flex-start;
        .num {
          width: 18%;
          height: 15%;
          background: black;
          border-radius: 50%;
          .row;
          justify-content: center;
          color: white;
        }
        div {
          width: 100%;
          height: 40%;
          // background: blanchedalmond;
          font-size: 1rem;
          p {
            line-height: 200%;
          }
        }
      }
    }
  }
  article:nth-of-type(3) {
    width: 100%;
    height: 31.25rem;
    background: #eeeeee;
    color: black;
    section {
      margin: 0 auto;
      width: 75rem;
      height: 100%;
      // background: brown;
      .column;
      justify-content: space-around;
      .title;
      aside {
        width: 100%;
        height: 70%;
        // background: burlywood;
        .row;
        justify-content: space-around;
        #support {
          width: 20%;
          height: 68%;
          background: white;
          border: 1px solid #ccc;
          border-radius: 0.25rem;
          .row;
          justify-content: center;
          transition: all ease 800ms;
          .content {
            width: 80%;
            height: 60%;
            // background: burlywood;
            .column;
            justify-content: space-around;
          }
        }
        #support:hover {
          // background: rgba(0, 0, 0, 0.2);
          // border: 1px solid #ee2d50;
          box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09),
            0 4px 2px rgba(0, 0, 0, 0.09), 0 8px 4px rgba(0, 0, 0, 0.09),
            0 16px 8px rgba(0, 0, 0, 0.09), 0 32px 16px rgba(0, 0, 0, 0.09);
          border-right: none;
          border-bottom: none;
          border-radius: 10%;
          // transform: rotate(360deg);
          // .content {
          //   color: white;
          // }
        }
      }
    }
  }
  article:nth-of-type(4) {
    width: 75rem;
    min-height: 20rem;
    // background: blanchedalmond;
    color: black;
    .column;
    justify-content: flex-start;
    .title;
    section {
      width: 100%;
      min-height: 30rem;
      margin-top: 50px;
      .row;
      justify-content: space-between;
      align-items: flex-start;
      aside:nth-of-type(1) {
        width: 30%;
        height: 100%;
        // background: blanchedalmond;
        .row;
        justify-content: space-around;
        align-items: flex-start;
        p {
          color: @bg;
          font-weight: bolder;
        }
        ul li:hover {
          border-bottom: 2px dashed rgba(1, 35, 124, 1);
        }
      }
      aside:nth-of-type(2) {
        width: 70%;
        min-height: 100%;
        // background: white;
        padding: 10px;
      }
    }
  }
}
</style>
