<template>
  <div id="cid_3" class="animated fadeInUp">
    <!-- 方案指南 -->
    <!-- @tab-click="handleClick" -->
    <p class="title">{{modular.content.title}}</p>
    <section>
      <el-tabs v-model="activeName">
        <el-tab-pane :label="modular.content.list[0].title1" name="first">
          <el-card shadow="hover" body-style="padding:0px" class="box-card">
            <div>
              <aside v-html="modular.content.list[0].remark1"></aside>
              <img :src="modular.content.list[0].image1" alt />
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane :label="modular.content.list[0].title2" name="second">
          <el-card shadow="hover" body-style="padding:0px" class="box-card">
            <div>
              <aside v-html="modular.content.list[0].remark2"></aside>
              <img :src="modular.content.list[0].image2" alt />
            </div>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "first",
    };
  },
  components: {},
  props: ["modular"],
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
  mounted() {},
};
</script>
<style scoped lang="less">
@import "../../../../assets/css/main.less";

#cid_3 {
  width: 75rem;
  min-height: 30rem;
  // background: blanchedalmond;
  .column;
  justify-content: center;
  .title {
    font-size: 1.5rem;
    font-weight: bolder;
  }
  section {
    width: 100%;
    height: 90%;
    // background: chocolate;
    .row;
    justify-content: center;
    // z-index: -9;
    .el-tabs {
      width: 100%;
      height: 100%;
      // background: olive;
      // .column;
      // justify-content: center;
      .box-card {
        width: 100%;
        min-height: 340px;
        border: none;
        // background: burlywood;
        div {
          width: 100%;
          min-height: 100%;
          // box-shadow: 120px 80px 40px 20px #0ff;
          // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3),
          // 0px 0px 20px rgba(0, 0, 0, 0.1) inset;
          .row;
          justify-content: space-around;
          align-items: flex-start;
          img {
            width: 50%;
          }
          aside {
            width: 45%;
            height: 100%;
            text-align: justify;
            // background: chocolate;
          }
        }
      }
    }
  }
}
</style>