<template>
  <div id="footer">
    <div class="footer">
      <div id="title">
        <!-- <p v-for="item in iconlist" :key="item.id">
          <span>
            <svg class="icon_title" aria-hidden="true">
              <use :xlink:href="item.icon" />
            </svg>
          </span>
          {{item.text}}
        </p>-->
      </div>
      <div id="content">
        <section>
          <aside>
            <span v-for="item in tel" :key="item.id">{{ item }}</span>
          </aside>
          <aside>
            <p>关注夏龙通信</p>
            <p>
              <span v-for="item in iconfa" :key="item.id" @mouseenter="enter(item)" @mouseleave="leave()">
                <i :class="item.icon"></i>
              </span>
            </p>
            <div class="code">
              <img :src="code" alt="微信" />
            </div>
          </aside>
        </section>
        <section>
          <aside v-for="item in context" :key="item.id">
            <strong>{{ item.name }}</strong>
            <a v-for="el in item.child" :key="el.id" @click="href(el)">{{ el.name }}</a>
          </aside>
        </section>
      </div>
      <div id="foot">
        <p>版权所有&copy;广东夏龙通信有限公司 <a style="color:white;" href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank">粤ICP备08100026号-2</a> 经营许可证:B1.B2-20130223 / 粤B1.B2-20070469</p>
        <p>
          <img :src="copy1" alt="网监" />
          <img :src="copy2" alt="网监" />
          <a href="http://www.beian.gov.cn/portal/recordQuery" target="_blank">粤公网安备：44030401000101</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import copy1 from "../../assets/images/copy1.png";
import copy2 from "../../assets/images/copy2.gif";
import wexin_ from "../../assets/images/wexin_code.jpg";
import weibo_ from "../../assets/images/weibo_code.jpg";
import qq_ from "../../assets/images/qq_code.jpg";
import "../../assets/js/iconfont.js";
export default {
  data () {
    return {
      weibo_,
      qq_,
      code: wexin_,
      copy1: copy1,
      copy2: copy2,
      iconfa: [
        { id: 1, icon: "fa fa-weixin" },
        { id: 2, icon: "fa fa-qq" },
        { id: 3, icon: "fa fa-weibo" },
      ],
      iconlist: [
        { id: 1, icon: "#icon-pei", text: "100倍故障赔偿" },
        { id: 2, icon: "#icon-qian", text: "3天无理由退款" },
        { id: 3, icon: "#icon-zan", text: "7x24小时服务" },
        { id: 4, icon: "#icon-bei", text: "0元快速备案" },
        { id: 5, icon: "#icon-zuanshi", text: "1V1专席秘书" },
      ],
      tel: [
        "销售服务热线",
        "139 2285 5777",
        "159 8956 8216",
        "134 8065 0699",
        "售前咨询",
        "备案服务",
        "云市场咨询",
      ],
      context: [],
    };
  },
  methods: {
    enter (item) {
      console.log(item.id);
      if (item.id == 1) {
        this.code = wexin_;
      } else if (item.id == 2) {
        this.code = qq_;
      } else if (item.id == 3) {
        this.code = weibo_;
      }
      document.querySelector(".code").style.display = "block";
    },
    leave () {
      document.querySelector(".code").style.display = "none";
    },
    href (el) {
      let local = "https://cloud.cdt.cc/";
      if (el.sid != 0) {
        this.$router.push({ path: "/solution", query: { ID: el.sid } });
        this.$eventbus.$emit("page_id", el.sid);
        localStorage.setItem("page_id", el.sid);
        document.querySelectorAll("#menu_nav li").forEach((el) => {
          el.style.borderBottom = "0";
        });
        document.querySelector(
          "#menu_nav li:nth-of-type(4)"
        ).style.borderBottom = "1px solid white";
      } else {
        if (el.href.substring(0, 21) == local) {
          window.open(el.href);
        } else {
          console.log(el);
          document.querySelectorAll("#menu_nav li").forEach((el) => {
            el.style.borderBottom = "0";
          });
          if (el.href == "/consultation") {
            document.querySelector(
              "#menu_nav li:nth-of-type(6)"
            ).style.borderBottom = "1px solid white";
            this.$router.push(el.href);
            let Id;
            switch (el.name) {
              case "技术支持":
                Id = 1;
                break;
              case "备案服务":
                Id = 2;
                break;
              case "常见问题":
                Id = 3;
                break;
              case "下载指南":
                Id = 4;
                break;
            }
            localStorage.setItem("consulID", Id);
            this.$eventbus.$emit("consulID", Id);
          } else if (el.href.substring(0, 6) == "/about") {
            document.querySelector(
              "#menu_nav li:nth-of-type(7)"
            ).style.borderBottom = "1px solid white";
            this.$router.push(el.href);
            let title;
            switch (el.name) {
              case "公司简介":
                title = "Company";
                break;
              case "新闻中心":
                title = "News";
                break;
              case "服务资质":
                title = "Service";
                break;
              case "合作伙伴":
                title = "Partners";
                break;
              case "加入夏龙":
                title = "Join";
                break;
              case "联系我们":
                title = "Contact";
                break;
            }
            this.$eventbus.$emit("title", title);
          }
        }
      }
    },
  },
  mounted () {
    this.$eventbus.$on("footMenu", (menu) => {
      // console.log("footMenu", menu);
      this.context = menu;
    });
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";

#footer {
  width: 100%;
  height: 35rem;
  color: white;
  background: @bg;

  .footer {
    width: 75rem;
    height: 93%;
    // background: wheat;
    margin: 0 auto;
    .column;
    justify-content: space-between;

    #title {
      width: 100%;
      height: 1%;
    }

    #content {
      width: 100%;
      height: 50%;
      // background: #ccc;
      // margin-top: 5%;
      .row;
      justify-content: space-around;

      section:nth-of-type(1) {
        width: 20%;
        height: 100%;
        // background: olivedrab;
        border-right: 0.0625rem solid white;
        .column;
        justify-content: space-around;

        aside:nth-of-type(1) {
          width: 100%;
          height: 70%;
          // background: #cccccc;
          .column;
          justify-content: space-around;
          align-items: flex-start;

          span {
            font-size: 0.625rem;
          }

          span:nth-of-type(2),
          span:nth-of-type(3),
          span:nth-of-type(4) {
            font-size: 1.5rem;
          }
        }

        aside:nth-of-type(2) {
          width: 100%;
          height: 20%;
          // background: orange;
          position: relative;

          p:nth-of-type(1) {
            font-size: 0.625rem;
          }

          p:nth-of-type(2) {
            width: 60%;
            // background: paleturquoise;
            .row;
            justify-content: space-around;
            margin-left: -0.625rem;

            span {
              width: 1.875rem;
              height: 1.875rem;
              // background: palegreen;
              border: 0.0625rem solid white;
              border-radius: 50%;
              .row;
              justify-content: center;
            }
          }

          .code {
            position: absolute;
            top: -95px;
            left: 0;
            width: 130px;
            height: 130px;
            background: white;
            display: none;

            img {
              width: 100%;
            }
          }
        }
      }

      section:nth-of-type(2) {
        width: 79%;
        height: 100%;
        // background: wheat;
        .row;
        justify-content: flex-start;

        aside {
          width: 20%;
          height: 100%;
          // background: bisque;
          .column;
          align-items: flex-start;
          justify-content: flex-start;
          line-height: 200%;
          padding-left: 1.25rem;

          a {
            color: white;
            text-decoration: none;
          }

          a:hover {
            text-decoration: underline;
          }
        }
      }
    }

    #foot {
      border-top: 1px solid #ccc;
      width: 100%;
      height: 15%;

      // background: bisque;
      p:nth-of-type(2) {
        // background: bisque;
        .row;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 1.25rem;
          margin-right: 0.625rem;
        }

        img:nth-of-type(2) {
          padding-top: 0.3125rem;
        }

        a {
          color: white;
        }
      }
    }
  }
}
</style>