<template>
  <div id="azure" v-show="ConShow" class="animated fadeInUp" v-html="item.content"></div>
</template>

<script>
// import logo from "../../assets/images/logo.png";
import { host } from "../../const";
export default {
  data() {
    return {
      item: [],
      ConShow: false,
    };
  },
  metaInfo: {
    title: "夏龙通信官网--云计算",
    meta: [
      {
        name: "keywords",
        content:
          "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入--云计算",
      },
      {
        name: "description",
        content:
          "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--云计算",
      },
    ],
  },
  components: {
    // Header
  },
  props: [],
  methods: {
    getazuresta() {
      let url = host + "/portal/home/getazurestack";
      this.$axios.get(url).then((res) => {
        this.ConShow = true;
        // console.log("Azure Stack", res.data.data);
        this.item = res.data.data;
      });
    },
  },
  mounted() {
    this.getazuresta();
    this.$eventbus.$emit("banner_id", "20");
    localStorage.setItem("banner_id", "20");
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";
#azure {
  width: 75rem;
  min-height: 50rem;
  // background: blanchedalmond;
  margin: 10px auto;
}
</style>