<template>
  <div class="app">
    <Project :project_Show="project_Show" />
    <HotSale :hotsale_Show="hotsale_Show" />
    <Product />
    <Advantage :advantage="advantage" />
    <News :news_Show="news_Show" />
    <Map :map="map" />
    <Partners :partner="partner" />
  </div>
</template>
<script>
import Partners from "../../components/partners/index";
import Map from "./components/map.vue";
import Project from "./components/project.vue";
import HotSale from "./components/hotsale.vue";
import Product from "./components/product.vue";
import News from "./components/news.vue";
// import Advert from "./components/advert.vue";
import Advantage from "./components/advantage.vue";
import { host } from "../../const";
export default {
  metaInfo: {
    title:
      "夏龙通信官网",
    meta: [
      {
        name: "keywords",
        content:
          "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入--首页",
      },
      {
        name: "description",
        content:
          "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--首页",
      },
    ],
  },
  data() {
    return {
      project_Show: false,
      hotsale_Show: false,
      news_Show: false,
      partner: [],
      map: {},
      advantage: {},
    };
  },
  components: {
    Partners,
    Map,
    Project,
    HotSale,
    Product,
    News,
    // Advert,
    Advantage,
  },
  mounted() {
    this.$eventbus.$emit("banner_id", "2");
    // this.$eventbus.$emit("nav_before", "0");
    window.addEventListener("scroll", this.handleScroll);

    let url = host + "/portal/home/gethomead";
    this.$axios.get(url).then((res) => {
      // console.log(res.data.data);
      this.partner = res.data.data.partner;
      this.map = res.data.data.map;
      this.advantage = res.data.data.advantage;
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop);
      if (scrollTop < 100) {
        // console.log(this);
        this.project_Show = false;
        this.hotsale_Show = false;
        this.news_Show = false;
      } else if (scrollTop >= 100 && scrollTop < 700) {
        this.project_Show = true;
      } else if (scrollTop >= 700 && scrollTop < 3300) {
        this.hotsale_Show = true;
      } else if (scrollTop >= 3300) {
        this.news_Show = true;
      }
    },
  },
};
</script>
<style scoped lang="less">
.app {
  width: 100%;
  min-height: 100vh;
}
</style>