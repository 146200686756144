<template>
  <div id="map" :style="'background-image:url('+(map.image)+')'"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["map"],
  methods: {},
  mounted() {},
};
</script>
<style scoped lang="less">
@import "../../../assets/css/main.less";
#map {
  width: 100%;
  height: 38rem;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
</style>