<template>
  <div id="cid_6" class="animated fadeInUp">
    <!-- 富文本 -->
    <p class="title">{{modular.content.title}}</p>
    <article v-html="modular.content.list[0].content"></article>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: ["modular"],
  methods: {},
  mounted() {
    console.log(this.modular);
  },
};
</script>
<style scoped lang="less">
@import "../../../../assets/css/main.less";
#cid_6 {
  width: 100%;
  min-height: 35rem;
  // background: #eee;
  transition: 1s all ease-in-out;
  .column;
  justify-content: space-around;
  .title {
    font-size: 1.5rem;
    font-weight: bolder;
  }
  article {
    width: 90%;
    min-height: 30rem;
  }
}
#cid_6:hover {
  // background: #ccc;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>