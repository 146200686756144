<template>
  <div id="company" v-show="ConShow" class="animated fadeInUp">
    <article>
      <section>
        <p class="title">
          <!-- <i class="el-icon-more"></i> -->
          公司介绍
        </p>
        <aside>
          <p>
            广东夏龙通信有限公司（以下简称“夏龙通信”）成立于2007年7月，中国总部设于广东深圳，在广州、
            东莞、青岛设有运营分支机构，主要经营数据通信、云计算和互联网增值业务。
          </p>
          <p>夏龙通信作为中国云计算资源整合服务供应商的佼佼者，致力于建设高保障数据中心。夏龙通信秉持中立路线，与所有运营商保持互联互通，承载高性能云计算服务平台，通过亚太地区高速互联宽带网，为客户专业提供数据中心托管（IDC）、云计算(Cloud)、互联网接入(ISP)、全球组网(MPLS VPN)、内容分发(CDN)、系统集成(SI)等服务。以卓越可靠的信息安全服务，优质稳定的数据传输，超大容量的信息处理能力，为诸多企业带来高效网络资源服务。</p>
        </aside>
      </section>
      <aside name="img_jpg">
        <img :src="casejpg" alt="公司介绍" />
      </aside>
    </article>
    <!-- 夏龙三大优势 -->
    <!-- <article> 
      <p class="p_title"></p>
      <section>
        <el-card
          body-style="padding:0"
          class="box-card"
          shadow="hover"
          v-for="item in cardlist"
          :key="item.id"
        >
          <div class="card" @click="tabs(item.id)">
            <span class="title">0{{item.id}}</span>
            <p class="p_title">
              {{item.title.tit}}
              <span>{{item.title.txt}}</span>
            </p>
            <ul v-for="el in item.list" :key="el.id">
              <li>{{el}}</li>
            </ul>
          </div>
        </el-card>
      </section>
      <section>
        <img :src="companylist[ind].url" alt />
        <aside>
          <p v-for="el in companylist[ind].list" :key="el">{{el}}</p>
        </aside>
      </section>
    </article>-->
    <article>
      <p class="tabbar">
        <span @click="tabbar(1)">深燃大厦</span>
        <span @click="tabbar(2)">梅林数据中心</span>
      </p>
      <section>
        <iframe width="100%" height="100%" :src="iframeSrc" frameborder="0"></iframe>
      </section>
    </article>
    <article>
      <p class="title_p">历史进程</p>
      <section>
        <el-carousel :interval="4000" type="card" height="300px">
          <el-carousel-item v-for="item in carousel" :key="item.id">
            <span class="title">{{item.time}}</span>
            <p v-html="item.con"></p>
          </el-carousel-item>
        </el-carousel>
      </section>
    </article>
    <article>
      <section>
        <p v-for="item in advantage" :key="item.id">
          <strong>{{item.strong}}</strong>
          {{item.span}}
        </p>
      </section>
    </article>
  </div>
</template>

<script>
import casejpg from "../../assets/images/company.jpg";

export default {
  data() {
    return {
      casejpg: casejpg,
      ind: 0,
      iframeSrc: "https://www.cdt.cc/dragon/views/index.html",
      advantage: [
        {
          strong: "中立优势",
          span: "夏龙通信与所有电信运营商保持友好关系，始终维持业务的中立性。",
        },
        {
          strong: "海外优势",
          span: "广泛开展海外合作，为客户提供境外业务发展咨询服务。",
        },
        {
          strong: "人才优势",
          span:
            "夏龙团队现有成员110多人，大部分从事电信行业多年，开展数据业务已超过十年，累积了丰富的行业经验，在业内除中国电信外，我们是最成熟和最完备的团队之一。",
        },
        {
          strong: "资源优势",
          span: "持有多项行业特许经营证照，拥有超过6000家中小企业客户。",
        },
      ],
      carousel: [
        {
          time: "2007",
          con:
            "夏龍通信创立<br />与长宽建立战略合作关系，并与三大运营商合作，搭建中立的数据交换平台<br />取得省ISP牌照及VPN、ICP、CALL CENTER牌",
        },
        {
          time: "2008",
          con: "部署夏龍通信自有CDN<br/>夏龍国际成立，获香港通信管理局ETS牌照",
        },
        {
          time: "2009",
          con: "并购纵横、沃克斯",
        },
        {
          time: "2010",
          con:
            "夏龍福田数据中心正式开业，建设完成自有核心机房<br/>与中国移动建立战略合作关系",
        },
        {
          time: "2011",
          con: "取得国家高新技术企业认证",
        },
        {
          time: "2012",
          con: "夏龍自有云平台投产，启动云联云计划",
        },
        {
          time: "2013",
          con: "取得全国IDC、ISP牌照",
        },
        {
          time: "2014",
          con: "与富士康建立战略合作关系",
        },
        {
          time: "2015",
          con: " IDC四期扩容完成交付投产",
        },
        {
          time: "2016",
          con: "双路市电改造完成交付投产",
        },
        {
          time: "2017",
          con: "与阿里云签署合作协议，推出混合云业务",
        },
        {
          time: "2018",
          con: "创建夏龍云平台，与微软，联想签署合作协议，推出夏龍定制云业务",
        },
        {
          time: "2019",
          con: "自主研发大健康预约管理平台、智慧文旅平台",
        },
        {
          time: "2020",
          con: "与华为云签署合作协议",
        },
      ],
      cardlist: [
        {
          id: 1,
          title: { tit: "夏龙MPLS-VPN", txt: "中立、通达的网络互连" },
          list: ["跨地区", "一网络", "一站式", "全球组网业务"],
        },
        {
          id: 2,
          title: { tit: "夏龙数据中心", txt: "稳定可靠的基础平台" },
          list: ["节能型", "环保", "合规性", "通用性"],
        },
        {
          id: 3,
          title: { tit: "夏龙云", txt: "卓越的云服务平台" },
          list: ["数据存储", "内容分发", "平台管理", "大数据分析"],
        },
      ],
      companylist: [
        {
          url: require("../../assets/images/company2.png"),
          list: [
            "夏龙云——卓越的云服务平台",
            "夏龙云为互联网增值服务运营商、中小型企业、政府、科研院所等广大企事业用户提供包括云主机、云托管、云存储等基础云服务、超算、内容分发与加速、视频托管与发布、企业IT、云电脑、云会议、游戏托管、应用托管等服务和解决方案。",
            "夏龙云的中小企业云解决方案，将为高速成长型企业提供分享资源模式，从数据网络搭建应用服务开发到数据存储、内容分发、平台管理、大数据分析提供一体化解决方案，与客户共同成长。",
          ],
        },
        {
          url: require("../../assets/images/company3.png"),
          list: [
            "稳定可靠的基础平台",
            "夏龙数据中心是夏龙通信T3+标准建设的五星级机房。夏龙数据中心拥有高速、安全、可靠的网络环境，是电信、联通、移动骨干网节点接入机房，是夏龙通信全力打造运营的深圳数据通信多线网络枢纽中心所在地，是拥有五星级标准的多线数据中心。夏龙数据中心在规划建设时已经采用了传输双路由出局、互联网出口双备份、核心网元双备份、核心设备双电源备份等多种冗余备份策略，充分保证了高安全性、稳定性的要求。 夏龙数据中心通过双10GE链路上联电信、联通、移动骨干网，采用全冗余网络结构（包括接入层到核心层设备、链路双备份），结合BGP4及OSPF动态路由协议，为客户提供了一个安全可靠、灵活多样的数据中心基础网络环境。",
            "贯彻夏龙公司持续发展和绿色环保高效理念，夏龙数据中心在设计规划和建设实施中，充分体现绿色数据中心的节能性、环保性、合规性、通用性、可观赏性、可用性、扩展性、安全性等特点，机房空间布局合理、装修材质环保和谐、现代风格与实用性并重，配电、网络、制冷等关键资源按需供给，智能化监控和管理确保机房安全、稳定运行。 高压直流供电系统、水循环系统、谐波整治系统等先进技术的应用使数据中心的运营成本较传统数据中心降低25%左右。",
          ],
        },
        {
          url: require("../../assets/images/company4.png"),
          list: [
            "夏龙MPLS-VPN——中立、通达的网络互连",
            "夏龙通信利采用成熟的MPLS技术，为全球企业客户提供独特的“跨地区、一网络、一站式”全球组网业务。随着网络规模的不断扩大，网络节点已基本覆盖中国大陆的主要大、中城市，并通过合作伙伴的网络进一步覆盖至境外的主要国家和地区。",
            "夏龙通信MPLS-VPN利用多协议标签交换（MPLS）技术，通过虚拟专用网络把分布在不同地点的公司/机构及设施连接起来，以安全可靠及高效率的网络，为网络数据流量提供目标、路由、转发和交换等能力。根据标签对数据包进行转发，因此在三层数据包中可以使用私有地址，是兼具成本效益及高度安全性的网络解决方案。",
            "“中立运营商”的精准定位，三大基础运营商电信、联通、移动的资源汇集，解决了中国互联网独有的互联互通问题，从而带来广阔的市场空间。 合作伙伴、ISP企业之间的对等互联，即保证了用户间相互访问的品质，同时减少了网间的成本结算，大大的降低了互联带宽出口的成本。",
          ],
        },
      ],
      ConShow: false,
    };
  },
  metaInfo: {
    title: "夏龙通信官网--公司介绍",
    meta: [
      {
        name: "keywords",
        content:
          "IDC,IDC机房,深圳IDC,深圳主机托管,深圳主机租用,深圳双线主机托管,深圳BGP主机托管,深圳BGP机房,深圳双线机房,云主机,带宽接入--公司介绍",
      },
      {
        name: "description",
        content:
          "夏龙通信是中国领先的IDC主机托管租用、云主机、云服务器、带宽接入、内容分发（CDN）、国际专线（IPLC）服务提供商。夏龙通信拥有自建的华南最大的中立、BGP多线、云计算机房——福田数据中心（梅林机房）以及其他八个中立骨干机房。夏龙通信，24小时专业为您服务！400-998-3388--公司介绍",
      },
    ],
  },
  components: {
    // Header
  },
  props: [],
  methods: {
    tabs(id) {
      // console.log(id);
      this.ind = id - 1;
    },
    tabbar(id) {
      // console.log(id);
      // this.map = map;
      let span1 = document.querySelector(".tabbar span:nth-of-type(1)").style;
      let span2 = document.querySelector(".tabbar span:nth-of-type(2)").style;
      let url = "https://www.cdt.cc/dragon/views/";
      if (id == 1) {
        this.iframeSrc = url + "index.html";
        span1.background = "rgba(1, 35, 124, 1)";
        span1.color = "white";
        span2.background = "white";
        span2.color = "black";
      } else if (id == 2) {
        this.iframeSrc = url + "default.html";
        span1.background = "white";
        span1.color = "black";
        span2.background = "rgba(1, 35, 124, 1)";
        span2.color = "white";
      }
    },
  },
  mounted() {
    this.ConShow = true;
    this.$eventbus.$emit("banner_id", 14);
    localStorage.setItem("banner_id", 14);
  },
};
</script>
<style scoped lang="less">
@import "../../assets/css/main.less";
#company {
  width: 100%;
  min-height: 100rem;
  //   background: cornsilk;
  color: black;
  .column;
  justify-content: space-around;
  article:nth-of-type(1) {
    width: 75rem;
    height: 31.25rem;
    // background: chocolate;
    .row;
    justify-content: space-between;
    section {
      width: 48%;
      height: 80%;
      // background: cornflowerblue;
      .column;
      justify-content: space-around;
      align-items: flex-start;
      .title;
      aside {
        width: 100%;
        height: 40rem;
        // background: cadetblue;
        .column;
        justify-content: flex-start;
        p {
          width:36rem ;
          text-indent: 2em;
          // background: chartreuse;
          text-align: justify;
          line-height: 200%;
          color: black;
        }
        p:nth-of-type(2) {
          margin-top: -10px;
        }
      }
    }
    aside[name="img_jpg"] {
      width: 50%;
      height: 100%;
      // background: brown;
      .row;
      justify-content: center;
      img {
        width: 100%;
      }
    }
  }
  article:nth-of-type(2) {
    width: 75rem;
    min-height: 30rem;
    // background: beige;
    .tabbar {
      width: 100%;
      height: 3rem;
      border: 0.0625rem solid black;
      .row;
      justify-content: center;
      span {
        width: 50%;
        height: 100%;
        .row;
        justify-content: center;
      }
      span:nth-of-type(1) {
        background: @bg;
        color: white;
      }
      span:nth-of-type(2) {
        background: white;
      }
    }

    section {
      width: 100%;
      height: 83vh;
      background: #eee;
    }
  }
  article:nth-of-type(3) {
    width: 75rem;
    height: 26rem;
    // background: bisque;
    .title_p {
      font-weight: 700;
      font-size: 1.25rem;
    }
    section {
      width: 100%;
      height: 20rem;
      .el-carousel__item {
        // background: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        .title {
          width: 100%;
          height: 2rem;
          .row;
          justify-content: center;
          font-size: 24px;
          font-weight: 700;
          padding-top: 50px;
        }
        p {
          width: 100%;
          height: 10rem;
          // background: chocolate;
          .column;
          justify-content: space-around;
          line-height: 250%;
        }
      }

      .el-carousel__item:nth-child(2n) {
        // background-color: rgba(153, 169, 191, 1);
        background-color:white
        // background-image: url(../../assets/images/combg.jpg);
      }

      .el-carousel__item:nth-child(2n + 1) {
        // background-color: rgba(211, 220, 230, 1);
         background-color:white
      }
    }
  }
  article:nth-of-type(4) {
    width: 100%;
    height: 20rem;
    // background: bisque;
    border-top: 0.0625rem solid black;
    .row;
    justify-content: center;
    section {
      width: 75rem;
      height: 60%;
      .column;
      justify-content: space-around;
      align-items: flex-start;
      font-size: 0.75rem;
      strong {
        margin-right: 2.1875rem;
        font-size: 1rem;
      }
    }
  }
}

  // article:nth-of-type(2) {
  //   width: 75rem;
  //   min-height: 40.625rem;
  //   //   background: bisque;
  //   .column;
  //   justify-content: center;
  //   .p_title {
  //     font-size: 2.25rem;
  //     font-weight: bolder;
  //   }
  //   section:nth-of-type(1) {
  //     width: 100%;
  //     height: 90%;
  //     // background: coral;
  //     padding-bottom: 2rem;
  //     border-bottom: 1px solid black;
  //     .row;
  //     justify-content: space-between;
  //     .box-card {
  //       width: 30%;
  //       height: 80%;
  //       // background: cornflowerblue;
  //       .card {
  //         width: 100%;
  //         height: 100%;
  //         transition: all ease 1s;
  //         // background: chocolate;
  //         // margin-left: 5%;
  //         padding: 29.8% 15%;
  //         .column;
  //         justify-content: space-around;
  //         align-items: flex-start;
  //         .title {
  //           transition: all ease 1s;
  //           font-size: 1rem;
  //           border-bottom: 0.125rem solid black;
  //         }
  //         p {
  //           .column;
  //           justify-content: space-around;
  //           font-size: 1rem;
  //           align-items: flex-start;
  //         }
  //         ul {
  //           margin-left: -1.325rem;
  //         }
  //       }

  //       .card:hover {
  //         background: #7897e8;
  //         color: white;
  //         .title {
  //           border-bottom: 0.125rem solid white;
  //         }
  //       }
  //     }
  //   }
  //   section:nth-of-type(2) {
  //     width: 75rem;
  //     height: 25rem;
  //     // background: bisque;
  //     padding-top: 2rem;
  //     .row;
  //     justify-content: space-around;
  //     img {
  //       width: 25%;
  //       margin: 0 10px 10px 0;
  //     }
  //     aside {
  //       width: 100%;
  //       height: 80%;
  //       .column;
  //       justify-content: space-around;
  //       p {
  //         line-height: 180%;
  //         text-indent: 2em;
  //         strong {
  //           margin-right: 10px;
  //         }
  //       }
  //     }
  //   }
  // }
</style>