<template>
  <div id="cid_4" class="animated fadeInUp">
    <!-- 更多资料 -->
    <div class="content">
      <p class="title">{{modular.content.title}}</p>
      <section>
        <aside v-for="el in modular.content.list" :key="el.id">
          <img :src="el.image" alt />
          <div>
            <p>
              <strong>{{el.title}}</strong>
              <span>{{el.des1}} | {{el.des2}}</span>
            </p>
            <el-button type="primary" @click="router_url(el.url)">立即查看</el-button>
          </div>
        </aside>
      </section>
      <!-- <section>
        <aside>
          <strong>售前专家免费服务</strong>
          <span>
            <em>联系专家</em>根据您提交的需求，将有专家免费服务。
          </span>
        </aside>
        <el-button type="primary">立即咨询</el-button>
      </section>-->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: ["modular"],
  methods: {
    router_url(url) {
      window.open(url);
    },
  },
  mounted() {},
};
</script>
<style scoped lang="less">
@import "../../../../assets/css/main.less";
#cid_4 {
  width: 100%;
  height: 50rem;
  // background: #f4f4f4;
  .content {
    width: 75rem;
    height: 100%;
    margin: 0 auto;
    .column;
    justify-content: space-around;
    align-items: center;
    .title {
      font-size: 1.5rem;
      font-weight: bolder;
    }
    section:nth-of-type(1) {
      width: 100%;
      height: 50%;
      // background: blanchedalmond;
      .row;
      flex-wrap: wrap;
      justify-content: space-between;
      aside {
        transition: all ease 1s;
        width: 48%;
        min-height: 10rem;
        background: #eee;
        .row;
        justify-content: flex-start;
        img {
          width: 30%;
          margin: 0 2%;
        }
        div {
          p {
            .column;
            justify-content: space-around;
            align-items: flex-start;
          }
        }
      }
      aside:hover {
        background: white;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }
    }
    section:nth-of-type(2) {
      transition: all ease 1s;
      width: 100%;
      height: 10%;
      background: white;
      .row;
      justify-content: space-between;
      align-items: center;
      aside {
        width: 60%;
        height: 100%;
        // background: chocolate;
        margin-left: 2%;
        .column;
        justify-content: space-around;
        align-items: flex-start;
        span {
          width: 100%;
          height: 30%;
          .row;
          justify-content: flex-start;
          // background: chocolate;
          em {
            font-style: normal;
            font-weight: 700;
            color: @bg;
            margin-right: 2%;
          }
        }
      }
      .el-button {
        margin-right: 2%;
      }
    }
    section:nth-of-type(2):hover {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }
}
</style>