<template>
  <div id="tabbar">
    <div id="title">
      <div class="title animated fadeInRightBig" v-show="tabbar_show">
        <a id="title_span" v-for="item in tabbar" :key="item.id" @click="change(item)">{{item}}</a>
      </div>
    </div>
  </div>
</template>

<script>
// import logo from "../../assets/images/logo.png";
export default {
  data() {
    return {
      tabbar_show: false
    };
  },
  props: ["tabbar"],
  methods: {
    change(item) {
      // this.$eventbus.$emit("tabbar_item", item);
      let num = this.tabbar.indexOf(item);
      // console.log(num);
      if (this.tabbar[num] == item) {
        document.querySelector(`#modular_${num + 1}`).scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth"
        });
      }
      let title = document.querySelectorAll(".title a");
      title.forEach(el => {
        // console.log(el.innerHTML, item.name);
        if (el.innerHTML === item) {
          el.style.color = "rgba(1, 35, 124, 1)";
          el.style.borderBottom = "2px solid rgba(1, 35, 124, 1)";
        } else {
          el.style.color = "black";
          el.style.borderBottom = "none";
        }
      });
    }
  },
  mounted() {
    this.tabbar_show = false;
    setTimeout(() => {
      this.tabbar_show = true;
    }, 500);
  }
};
</script>
<style scoped lang="less">
@import "../../../assets/css/main.less";
#title {
  width: 75rem;
  height: 100%;
  // background: white;
  // background: chocolate;
  // border-radius:0 0 5px 5px ;
  // z-index:9999;
  color: black;
  .row;
  justify-content: center;
  transition: all ease 2s;
  margin: 0 auto;
  .title {
    width: 100%;
    height: 100%;
    //background: blanchedalmond;
    .row;
    justify-content: space-around;
    color: black;
    padding-bottom: 0.1rem;
    a {
      font-size: 1rem;
      text-decoration: none;
      color: black;
      // padding: 1rem 0 0 1rem;
      padding: 0.5rem;
    }
    a:hover {
      cursor: default;
      font-weight: bolder;
      border-bottom: 2px solid rgba(1, 35, 124, 1);
    }
  }
}
</style>